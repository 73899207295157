<form [formGroup]="coveringPractitionerForm">
    <!-- Start coveringPractitioner FormArray -->
    <div class="row">
        <div class="col form-array" formArrayName="coveringPractitionerArray">
            <div class="row form-array-item mt-4"
                *ngFor="let item of coveringPractitionerForm.get('coveringPractitionerArray')?.controls; let coveringPractitionerIndex = index;">
                <div class="col section" style="border-top: 1px solid #eee;">
                    <div class="row form-group">
                        <div class="col-12"><button type="button" class="remove-item"
                                (click)="RemoveCoveringPractitioner(coveringPractitionerIndex)"><i
                                    class="icon-delete"></i></button>
                        </div>
                    </div>
                    <div class="row" [formGroupName]="coveringPractitionerIndex">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-5 label">Provider Name</div>
                                <div class="col-7 input-feild">
                                    <input type="text" class="form-control" (change)="onChange()"
                                        placeholder="Enter Provider Name" formControlName="providerName">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Provider Type</div>
                                <div class="col-7 input-feild">
                                    <mat-radio-group aria-labelledby="radio-group" class="radio-group"
                                        formControlName="providerType">
                                        <mat-radio-button value="MD/DO">MD/DO</mat-radio-button>
                                        <mat-radio-button value="DPM">DPM</mat-radio-button>
                                        <mat-radio-button value="DC">DC</mat-radio-button>
                                        <mat-radio-button value="DDS/DMD">DDS/DMD</mat-radio-button>
                                        <mat-radio-button value="Allied Health">Allied Health
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="row form-group" [ngSwitch]="getProviderType(coveringPractitionerIndex)">
                                <div class="col">
                                    <div class="row form-group" *ngSwitchCase="'MD/DO'">
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="MD_DO_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngSwitchCase="'DPM'">
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="DPM_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngSwitchCase="'DC'">
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="DC_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngSwitchCase="'DDS/DMD'">
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="DDS_DMD_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngSwitchCase="'Allied Health'">
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="ALLIED_PROVIDERS_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngSwitchDefault>
                                        <div class="col-5 label">Speciality</div>
                                        <div class="col-7 input-feild">
                                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                                [items]="allSpecialities_Options" formControlName="specialty"
                                                placeholder="Select Specialty"
                                                filterPlaceholder="Begin typing to Select Specialty" name="specialty">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Phone Number</div>
                                <div class="col-7 input-feild">
                                    <app-phone-input formControlName="phoneNumber"></app-phone-input>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col">
                                    <eddy-upload-file [docInfo]="'Office Coverage'" [userId]="userId"
                                        [text]="'Upload Office Coverage'">
                                    </eddy-upload-file>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12"><button type="button" class="add-item rectangle-button orange-button"
                        (click)="AddCoveringPractitioner()">Add another covering practitioner</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End coveringPractitioner FormArray -->
</form>