<div class="row">
  <div class="col">
    <div *ngIf="!archived" class="row form-group">
      <div class="col-5 label">
        {{ text }}
        <div class="count">
          <i class="icon-references"></i> File: {{ count }}
        </div>
      </div>
      <div class="col-7 input-feild">
        <ngx-file-drop class="upload-file-component" dropZoneLabel="Drop files here" (onFileDrop)="Dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="dragNdropContent">Drag & Drop Files Here</span>
              <span class="dragNdropContent">Or</span>
              <button class="dndUploadBtn" type="button" (click)="openFileSelector()">Click to Upload</button>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </div>
    <div *ngIf="archived && files && files.length > 0" class="row label archived">
      <div class="col">
        Archived {{ text }} Attachments
      </div>
    </div>
    <div class="row" *ngIf="files && files.length > 0">
      <div class="col">
        <div class="items">
          <div class="item" *ngFor="let file of files">
            <!-- <div class="pic"></div> -->
            <div class="name">{{ file.fileName | truncate }}</div>
            <div class="date"><i class="icon-time"></i> {{ file.updatedAt | date }}</div>
            <div class="show"><button type="button" (click)="ShowAttachment(file)" class="show"><i
                  class="icon-show-image"></i></button></div>
            <div class="delete"><button type="button" (click)="DeleteAttachment(file)" class="delete"><i
                  class="icon-rubbish-two"></i></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>