<nb-layout>
    <nb-layout-column class="registerCardContainer">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <nb-card>
                    <nb-card-body>
                        <div class="row">
                            <div class="payment">
                                <div class="image">
                                    <img *ngIf="wasSuccessfull" src="assets/images/payment/payment-success.png" alt="">
                                    <img *ngIf="!wasSuccessfull" src="assets/images/payment/payment-fail.png" alt="">
                                </div>
                                <div [ngClass]="wasSuccessfull ? 'success-header': 'fail-header'">Payment {{paymentResult}}</div>
                                <div class="body">
                                    <p *ngIf="wasSuccessfull">Your payment was successfully so you can now continue using Eddy.</p>
                                    <p *ngIf="!wasSuccessfull">Your payment was unsuccessfully.</p>
                                </div>
                                <div class="button-place">
                                    <button class="rectangle-button blue-button" type="button" (click)="goToPanel()">{{ wasSuccessfull ? 'Begin Using Eddy' : 'Subscription Plan' }}</button>
                                </div>
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>