<form [formGroup]="docForm">
    <!-- Start DEA FormArray -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        formControlName="isPrimary">
                        <mat-radio-button [value]="true" aria-labelledby="example-radio-group-label"
                            (click)="isPrimaryChange(true)" class="example-radio-group">
                            Primary
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="isPrimaryChange(false)"
                            aria-labelledby="example-radio-group-label" class="example-radio-group">
                            Historical
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Federal DEA License Number <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild"><input type="text" class="form-control"
                        placeholder="Enter Federal DEA License Number" formControlName="deaNum"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">DEA X Waiver, If applicable
                </div>
                <div class="col-7 input-feild"><input type="text" class="form-control"
                        placeholder="Enter DEA X Waiver" formControlName="deaXWaiver"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">DEA State <span class="text-danger">*</span></div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="value" bindValue="value" [items]="stateOptions"
                        formControlName="stateDEA" placeholder="Select State"
                        filterPlaceholder="Begin typing to Select State" name="stateDEA">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Issue date of DEA License <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="issuanceDEA" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)" formControlName="issuanceDEA"
                        #issuanceDEA="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="issuanceDEA.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration date of DEA License <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="expireDEA" placement="bottom" ngbDatepicker
                        type="date" (ngModelChange)="onDateChange($event)" formControlName="expireDEA"
                        #expireDEA="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="expireDEA.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Drug Schedules</div>
                <div class="col-7 input-feild date-picker">
                    <div class="row" *ngIf="drugSchedulesOptions && drugSchedulesOptions.length">
                        <div class="col" *ngFor="let _item of drugSchedulesOptions">
                            <mat-checkbox style="margin-left: 2px;font-size: 12px;"
                                (change)="onChangeDEADrugSchedule(_item.value)"
                                [checked]="checkDEADrugSchedule(_item.value)">
                                {{_item.label}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End DEA FormArray -->
</form>