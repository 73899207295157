<!-- <div class="row breadcrumbs">
    <div class="col">
        <app-breadcrumbs></app-breadcrumbs>
    </div>
</div> -->
<div class="row">
    <div class="col">
        <h4 class="mt-10 mb-20">Practices</h4>
    </div>
</div>
<form [formGroup]="primaryPracticeForm">
    <div class="row custom-form-design form-group">
        <div class="col">
            <div class="row form-group">
                <div class="col input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label"
                        *ngIf="!primaryPracticeForm?.controls?.isPrior?.value" class="example-radio-group"
                        formControlName="isPrimary">
                        <mat-radio-button [value]="true" aria-labelledby="example-radio-group-label"
                            (click)="isPrimaryChange($event)" class="example-radio-group">
                            Primary Practice
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="isPrimaryChange(false)"
                            aria-labelledby="example-radio-group-label" class="example-radio-group">
                            Historical
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Physician Group/Practice Name<span class="text-danger">*</span>
                </div>
                <div class="col input-feild">
                    <div class="row form-group">
                        <div class="col">
                            <input formControlName="physGroup1" class="form-control" ngx-google-places-autocomplete
                                [options]='options' #placesRef="ngx-places" (onAddressChange)="AddressChange($event)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Address of primary practice</div>
                <div class="col-7 input-feild">
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" ngx-google-places-autocomplete
                                [options]='options' #placesRef="ngx-places" (onAddressChange)="primAddressChange($event)"
                                placeholder="Street Address 1" formControlName="addressStreetPrimPract">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Street Address 2"
                                formControlName="addressStreet2PrimPract">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="City"
                                formControlName="addressCityPrimPract"></div>
                        <div class="col"><input type="text" class="form-control" placeholder="State/Province"
                                formControlName="addressStatePrimPract">
                        </div>
                        <div class="col"><input type="text" class="form-control" placeholder="Zip"
                                formControlName="addressZipPrimPract"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="County Name"
                                formControlName="countyPract"></div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Contact Phone Number</div>
                <div class="col-7 input-feild">
                    <app-phone-input formControlName="officeNoPrimPract"></app-phone-input>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isManagerSame?.value">
                <div class="col-5 label">Secondary Contact Number</div>
                <div class="col-7 input-feild">
                    <app-intl-tel-input [preferredCountries]="['us']" [phoneType]="'MOBILE'" [enablePlaceholder]="true"
                        formControlName="PhoneAfterHours">
                    </app-intl-tel-input>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Fax number</div>
                <div class="col-7 input-feild"><input type="text" mask="(000) 000-0000" class="form-control"
                        placeholder="Enter office fax number" formControlName="faxOfficePrimPract"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Primary Practice Office Email</div>
                <div class="col-7 input-feild">
                    <input type="email" class="form-control" placeholder="Enter Office Email"
                        formControlName="emailOfficePrimPract">
                </div>
            </div>
        
         <div class="row form-group">
                <div class="col-5 label">dba
                </div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Group/Corporate Name"
                        formControlName="physGroup2"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">WebSite URL</div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter WebSite URL"
                        formControlName="website"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Practice start date</div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="startPracticing" placement="top" ngbDatepicker
                        type="date" (keyup)="onDateChange($event)" formControlName="startPracticing"
                        #startPracticing="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="startPracticing.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">
                    Terminate Practice?
                </div>
                <div class="col-7 input-feild">
                    No <mat-slide-toggle formControlName="isPrior">
                        Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row form-group" *ngIf="primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Practice end date</div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="endPracticing" placement="top" ngbDatepicker
                        type="date" (keyup)="onDateChange($event)" formControlName="endPracticing"
                        #endPracticing="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="endPracticing.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
        
            <div class="row form-group" *ngIf="isOfficDay(0)">
                <div class="col-5 label">Monday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="mondayTimeStartOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.mondayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="mondayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(1)">
                <div class="col-5 label">Tuesday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.tuesdayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="tuesdayTimeStartOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.tuesdayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="tuesdayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(2)">
                <div class="col-5 label">Wednesday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.wednesdayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="wednesdayTimeStartOffice" class="form-control" placeholder="Start">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.wednesdayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="wednesdayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(3)">
                <div class="col-5 label">Thursday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.thursdayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="thursdayTimeStartOffice" class="form-control" placeholder="Start">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.thursdayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="thursdayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(5)">
                <div class="col-5 label">Saturday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.saturdayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="saturdayTimeStartOffice" class="form-control" placeholder="Start">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.saturdayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="saturdayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(4)">
                <div class="col-5 label">Friday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.fridayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="fridayTimeStartOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.fridayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="fridayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="isOfficDay(6)">
                <div class="col-5 label">Sunday Office Hours</div>
                <div class="col-7 input-feild">
                    <div class="row">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">Start</div>
                                <!-- value={{primaryPracticeForm?.controls?.sundayTimeStartOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="sundayTimeStartOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-4 label">End</div>
                                <!-- value={{primaryPracticeForm?.controls?.sundayTimeEndOffice?.value}} -->
                                <div class="col-8 input-feild slide-toggle"><input type="time"
                                        formControlName="sundayTimeEndOffice" class="form-control" placeholder="Start"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Open Practice Status: Are you accepting new patients?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle formControlName="acceptNewPatients">
                        Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Are you accepting new Medicare patients?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle
                        formControlName="acceptMedicarePatients">Yes</mat-slide-toggle>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Are you accepting new Medicaid patients?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle
                        formControlName="acceptMedicaidPatients">Yes</mat-slide-toggle>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Are there any practice limitations? If yes, please explain
                    below.
                </div>
                <div class="col-7 input-feild">No <mat-slide-toggle formControlName="isPractLimits">
                        Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Do you currently supervise ARNPs or PAs?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle formControlName="isSupervise">
                        Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <!-- Start practLimits Multi Select -->
            <div class="row form-group"
                *ngIf="primaryPracticeForm?.controls?.isPractLimits?.value && !primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Practice limitations</div>
                <div class="col-7 input-feild">
                    <section class="multi-select">
                        <mat-checkbox
                            *ngFor="let control of primaryPracticeForm?.controls?.practLimits?.controls; let practLimitsIndex = index"
                            (change)="GetSelectedpractLimitsValue()" class="two-item" [formControl]="control">{{
                            practLimits[practLimitsIndex] }}
                        </mat-checkbox>
                    </section>
                </div>
            </div>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Do you have hospital inpatient coverage plan?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle formControlName="hasCoveragePlan">
                        Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <primary-practice-coverage-plan [userId]="userId"
                *ngIf="primaryPracticeForm?.controls?.hasCoveragePlan?.value && !primaryPracticeForm?.controls?.isPrior?.value"
                [coveragePlans]="coveragePlans[0]" (coveragePlansChange)="coveragePlansChanged($event)">
            </primary-practice-coverage-plan>
            <div class="row form-group" *ngIf="!primaryPracticeForm?.controls?.isPrior?.value">
                <div class="col-5 label">Do you have office covering practitioners/call group?</div>
                <div class="col-7 input-feild">No <mat-slide-toggle formControlName="hasCoveringPractitioners">Yes
                    </mat-slide-toggle>
                </div>
            </div>
            <primary-practice-covering-practitioner [userId]="userId"
                *ngIf="primaryPracticeForm?.controls?.hasCoveringPractitioners?.value && !primaryPracticeForm?.controls?.isPrior?.value"
                [coveringPractitioners]="coveringPractitioners[0]"
                (coveringPractitionersChange)="coveringPractitionersChanged($event)">
            </primary-practice-covering-practitioner>
            <div class="row button-section">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i class="icon-check"></i>
                        Save</button>
                </div>
            </div>
        </div>
    </div>
</form>