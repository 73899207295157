import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-save-cds",
  templateUrl: "save.cds.component.html",
  styleUrls: ["save.cds.component.scss"],
})
export class SaveProviderCDSComponent extends SaveDocumentItem<SaveProviderCDSComponent> {
  dateFields = ['issuanceCDS', 'expireCDS'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  drugSchedulesOptions: Array<IOption> = this.selectOptionService.getDrugSchedulesCDS();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderCDSComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
      cdsNum: [info ? info.cdsNum : "", Validators.required],
      stateRegCDS: [info ? info.stateRegCDS : null, Validators.required],
      cdsDrugSchedule: [info ? info.cdsDrugSchedule : []],
      issuanceCDS: [info ? this.service.ConvertStringToDate(info.issuanceCDS) : "", Validators.required],
      expireCDS: [info ? this.service.ConvertStringToDate(info.expireCDS) : "", Validators.required],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
    });

    return this.docForm;
  }

  isPrimaryChange(event: any) {
    this.docForm.get("isPrimary").setValue(event);
  }

  checkCDSDrugSchedule(item: string) {
    return this.docForm && this.docForm.get("cdsDrugSchedule").value &&
      this.docForm.get("cdsDrugSchedule").value.indexOf(item) > -1 ?
      true : false;
  }

  onChangeCDSDrugSchedule(item: string) {
    let drugSchedules: string[] = this.docForm.get("cdsDrugSchedule").value;
    if (!drugSchedules) drugSchedules = [];
    if (drugSchedules.indexOf(item) <= -1) drugSchedules.push(item);
    else drugSchedules.splice(drugSchedules.indexOf(item), 1);
    this.docForm.get("cdsDrugSchedule").setValue(drugSchedules);
  }
}
