<form [formGroup]="personalInformationForm">
    <div class="row custom-form-design form-group">
        <div class="col-12">
            <div class="row form-group section">
                <div class="col">
                    <div class="row">
                        <div *ngIf="editModal" class="col-6 label"><b>Update Government Issued Photo ID</b></div>
                    </div>
                    <div class="row form-group ">
                        <div class="col-5 label">Gov Issued ID Type <span class="text-danger">*</span></div>
                        <div class="col-7 input-feild ">
                            <div class="row">
                                <div class="col-6">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="govIssuedTypeSelected"
                                        [ngModelOptions]="{standalone:true}" (change)="onChange($event)">
                                        <mat-radio-button value="driver-license">Driver License
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-6">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="govIssuedTypeSelected"
                                        [ngModelOptions]="{standalone:true}" (change)="onChange($event)">
                                        <mat-radio-button value="passport">Passport
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-item">
                        <div class="row form-group">
                            <div class="col-5 label">State of Gov Issued ID</div>
                            <div class="col-7 input-feild">
                                <ng-select class="auto-select" bindLabel="value" bindValue="value"
                                    [items]="stateOptions" formControlName="stateDriverLicense"
                                    placeholder="Select State of Gov Issued ID"
                                    filterPlaceholder="Begin typing to Select State" name="stateDriverLicense"
                                    (change)="onChange($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-5 label">Gov Issued ID Number </div>
                            <div class="col-7 input-feild"><input type="text" class="form-control"
                                    placeholder="Gov Issued ID Number" formControlName="driverLicenseNo"
                                    (change)="onChange($event)"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col-5 label">Gov Issued ID Expiration Date<span class="text-danger">*</span>
                            </div>
                            <div class="col-7 input-feild date-picker">
                                <input class="form-control" placeholder="mm/dd/yyyy" name="driverLicenseExpireDate"
                                    placement="top" ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                                    formControlName="driverLicenseExpireDate" #driverLicenseExpireDate="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="calendar" (click)="driverLicenseExpireDate.toggle()" type="button">
                                        <i class="icon-calender"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!editModal" class="row">
                        <div class="col">
                            <eddy-upload-provider-document [docInfo]="'Driver License'"
                                [text]="'Upload Passport/Drivers License'" (onChange)="onChange($event)">
                            </eddy-upload-provider-document>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="editModal" class="col">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-5 form-group">
                            <button type="button" *ngIf="editModal" (click)="closeModal()"
                                class="go-back rectangle-button mb-0">
                                Cancel</button>
                        </div>
                        <div class="col-7 form-group">
                            <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i
                                    class="icon-check"></i> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>