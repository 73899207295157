<input 
  type="text" 
  mask="(000) 000-0000" 
  id="inputCountry" 
  ng2TelInput 
  [ng2TelInputOptions]="options" 
  name="phone" 
  [(ngModel)]="phone"
  (change)="onChange($event)" 
  (keyup)="onChange($event)" 
  (hasError)="hasError($event)" 
  (ng2TelOutput)="getPhone($event)"
  (intlTelInputObject)="telInputObject($event)" 
  (countryChange)="onCountryChange($event)" 
  class="phoneInput form-control" 
>