<form [formGroup]="docForm">
    <!-- Start CDS FormArray -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        formControlName="isPrimary">
                        <mat-radio-button [value]="true" aria-labelledby="example-radio-group-label"
                            (click)="isPrimaryChange(true)" class="example-radio-group">
                            Primary
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="isPrimaryChange(false)"
                            aria-labelledby="example-radio-group-label" class="example-radio-group">
                            Historical
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">CDS Number <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter CDS Number"
                        formControlName="cdsNum"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">State of CDS Registration <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="value" bindValue="value" [items]="stateOptions"
                        formControlName="stateRegCDS" placeholder="Select State"
                        filterPlaceholder="Begin typing to Select State" name="stateRegCDS">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Drug Schedules</div>
                <div class="col-7 input-feild date-picker">
                    <div class="row" *ngIf="drugSchedulesOptions && drugSchedulesOptions.length">
                        <div class="col" *ngFor="let _item of drugSchedulesOptions">
                            <mat-checkbox style="margin-left: 2px;font-size: 12px;"
                                (change)="onChangeCDSDrugSchedule(_item.value)"
                                [checked]="checkCDSDrugSchedule(_item.value)">
                                {{_item.label}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Issue Date of CDS <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="issuanceCDS" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)" formControlName="issuanceCDS"
                        #issuanceCDS="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="issuanceCDS.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration Date of CDS <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="expireCDS" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)" formControlName="expireCDS"
                        #expireCDS="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="expireCDS.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End CDS FormArray -->
</form>