import { Component, EventEmitter, Inject, Optional, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { CommonFormService } from "src/app/services/common-form.service";
import { EditPersonalInfoItem } from "src/app/shared/utils/editPersonalInfoItem";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { SelectOptionService } from "src/app/services/select-option.service";

@Component({
  selector: "eddy-provider-CAQH-documents",
  templateUrl: "CAQH.component.html",
  styleUrls: ["CAQH.component.scss"],
})
export class CAQHDocumentsComponent extends EditPersonalInfoItem<CAQHDocumentsComponent> {
  personalInformationForm: FormGroup;
  personalInformationInfo: PersonalInformation;
  arrayList = [];
  dateList = [];

  @Output("onChangePersonalInformation")
  onChangeForm = new EventEmitter();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() dialogRef: MatDialogRef<CAQHDocumentsComponent>,
  ) {
    super(builder, service, dialogData, dialogRef)
  }

  CreateForm() {
    this.personalInformationForm = this.builder.group({
      caqhNumber: [""],
      caqhUsername: [""],
      caqhPassword: [""],
    });

    if (this.personalInformationInfo) {
      this.PatchValue(this.personalInformationInfo);
    }
  }

  PatchValue(personalInformation: PersonalInformation) {
    if (personalInformation && this.personalInformationForm) {
      this.personalInformationInfo = personalInformation;
      for (let item of Object.keys(personalInformation)) {
        if (this.personalInformationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.personalInformationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(personalInformation[item])
                );
            } else {
              this.personalInformationForm.get(item).patchValue(personalInformation[item]);
            }
          }
        }
      }
    }
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let personalInformation = this.personalInformationForm.value;
    this.ConvertDate(personalInformation);

    const isValid = this.personalInformationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.personalInformationForm);
    this.onChangeForm.emit({ ...personalInformation, isValid });
  }

}
