<!-- <div class="row breadcrumbs">
    <div class="col">
        <app-breadcrumbs></app-breadcrumbs>
    </div>
</div> -->
<div class="row">
    <div class="col">
        <h4 class="mt-10 mb-20">Training</h4>
    </div>
</div>
<form [formGroup]="trainingForm">
    <div class="row custom-form-design">
        <div class="col">
            <div class="row form-group">
                <div class="col-5 label">Training Institution <span class="text-danger">*</span></div>
                <div class="col-7 input-feild">
                    <input formControlName="codeSchool" class="form-control" ngx-google-places-autocomplete
                        #placesRef="ngx-places" (onAddressChange)="AddressChange($event)" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Address of Training Institution</div>
                <div class="col-7 input-feild">
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Street Address 1"
                                formControlName="addressStreetInst1"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Street Address 2"
                                formControlName="addressStreetInst2"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="City"
                                formControlName="addressCityInst"></div>
                        <div class="col"><input type="text" class="form-control" placeholder="State/Province"
                                formControlName="addressStateInst"></div>
                        <div class="col"><input type="text" class="form-control" placeholder="Zip"
                                formControlName="addressZipInst"></div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Date training program began</div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="startDatePrgAddInst" placement="top"
                        ngbDatepicker type="date" (keyup)="onDateChange($event)" formControlName="startDatePrgAddInst"
                        #startDatePrgAddInst="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="startDatePrgAddInst.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                    <small id="emailHelp" class="form-text text-muted">If you are unsure of the exact day, choose
                        "1"</small>

                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Date training program ended</div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="endDatePrgAddInst" placement="top"
                        ngbDatepicker type="date" (keyup)="onDateChange($event)" formControlName="endDatePrgAddInst"
                        #endDatePrgAddInst="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="endDatePrgAddInst.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Type of training program <span class="text-danger">*</span></div>
                <div class="col-7 input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        formControlName="typePrgAddInst">
                        <div class="row">
                            <div class="col">
                                <mat-radio-button value="Internship">Internship</mat-radio-button>
                                <mat-radio-button value="Residency">Residency</mat-radio-button>
                                <mat-radio-button value="Fellowship">Fellowship</mat-radio-button>
                                <mat-radio-button value="Teaching Appointment">Teaching Appointment</mat-radio-button>
                                <mat-radio-button value="Transitional Year">Transitional Year</mat-radio-button>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Primary Contact Number</div>
                <div class="col-7 input-feild">
                    <app-phone-input formControlName="phoneInst"></app-phone-input>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Did you complete a training program at this institution? <span
                        class="text-danger">*</span></div>
                <div class="col-7 input-feild">No <mat-slide-toggle
                        formControlName="isProgramInst">Yes</mat-slide-toggle></div>
            </div>
            <div class="row form-group" *ngIf="!trainingForm.controls.isProgramInst.value ">
                <div class="col-5 label">If no, please explain</div>
                <div class="col-7 input-feild">
                    <textarea name="supPhysSpecialty" rows="5" formControlName="noteInst"></textarea>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Department</div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="departmentOptions"
                        formControlName="deptPrgAddInst" placeholder="Select Department Of Training"
                        filterPlaceholder="Begin typing to Select Department" name="deptPrgAddInst">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Full name of Program Director for training program</div>
                <div class="col-7 input-feild"><input type="text" class="form-control"
                        placeholder="Type N/A if this field does not apply" formControlName="directorPrgAddInst"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Email address for Program Director for training</div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter Email Address"
                        formControlName="emailDirPrgAddInst"></div>
            </div>
            <div class="row button-section">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
        </div>
    </div>
</form>