<form [formGroup]="docForm">
    <!-- Start Insurance FormArray -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        formControlName="isPrimary">
                        <mat-radio-button [value]="true" aria-labelledby="example-radio-group-label"
                            (click)="isPrimaryChange(true)" class="example-radio-group">
                            Primary Insurance
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="isPrimaryChange(false)"
                            aria-labelledby="example-radio-group-label" class="example-radio-group">
                            Historical
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Policy Number <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter Policy Number"
                        formControlName="policyCarr">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Carrier's Name</div>
                <div class="col-7 input-feild">
                    <div class="row form-group">
                        <div class="col">
                            <input placeholder="Carrier's Name" class="form-control" formControlName="carrName"
                                ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                                (onAddressChange)="AddressChange($event)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Coverage Start Date <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" (ngModelChange)="onDateChange($event)"
                        name="startingCarr" placement="bottom" ngbDatepicker type="date" formControlName="startingCarr"
                        #startingCarr="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="startingCarr.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" (ngModelChange)="onDateChange($event)"
                        name="endingCarr" placement="bottom" ngbDatepicker type="date" formControlName="endingCarr"
                        #endingCarr="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="endingCarr.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Amount of coverage per occurrence</div>
                <div class="col-7 input-feild"><input type="number" class="form-control" placeholder="Enter Amount"
                        formControlName="amountCovOcc"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Amount of coverage aggregate</div>
                <div class="col-7 input-feild"><input type="number" class="form-control" placeholder="Enter Amount"
                        formControlName="amountCovAgg"></div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End Insurance FormArray -->
</form>