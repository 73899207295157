<form [formGroup]="docForm">
    <!-- Start License Form -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col input-feild">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        formControlName="isPrimary">
                        <mat-radio-button [value]="true" aria-labelledby="example-radio-group-label"
                            (click)="isPrimaryChange(true)" class="example-radio-group">
                            Primary
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="isPrimaryChange(false)"
                            aria-labelledby="example-radio-group-label" class="example-radio-group">
                            Historical
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">State of License <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="value" bindValue="value" [items]="stateOptions"
                        formControlName="stateStLicense" placeholder="Select State"
                        filterPlaceholder="Begin typing to Select State" name="stateStLicense">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">State License Number <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="text" class="form-control"
                        placeholder="Enter State License number" formControlName="numStLicense"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Individual License Type</div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="licenseTypeOptions"
                        formControlName="typeStLicense" placeholder="Select License type"
                        filterPlaceholder="Begin typing to Select License type" name="typeStLicense">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Issue date of state license <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="issuanceStLicense" placement="top"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                        formControlName="issuanceStLicense" #issuanceStLicense="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="issuanceStLicense.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration date of state license <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="expireStLicense" placement="top"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                        formControlName="expireStLicense" #expireStLicense="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="expireStLicense.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End License Form -->
</form>