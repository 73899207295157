<form [formGroup]="certificationForm">
    <div class="row custom-form-design form-group">
        <div class="col-12">
            <div class="row form-group section">
                <div class="col">
                    <div class="row">
                        <div *ngIf="!editModal" class="col-6 label"><b>PALS</b> <span class="text-danger">*</span>
                        </div>
                        <div *ngIf="editModal" class="col-6 label"><b>Update PALS</b></div>
                        <!-- <div class="col-6 input-feild">No <mat-slide-toggle formControlName="isPediatric">Yes
                            </mat-slide-toggle>
                        </div> -->
                    </div>
                    <div class="form-group-item">
                        <div class="row form-group">
                            <div class="col-5 label">Pediatric Advanced Life Support Number <span class="text-danger">*</span></div>
                            <div class="col-7 input-feild"><input type="text" class="form-control" (change)="onChange($event)"
                                    placeholder="Enter PALS Number" formControlName="numberPALS"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col-5 label">Pediatric Advanced Life Support Expiration Date <span
                                    class="text-danger">*</span></div>
                            <div class="col-7 input-feild date-picker">
                                <input class="form-control" placeholder="mm/dd/yyyy" name="expirePediatric" placement="bottom"
                                    ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                                    formControlName="expirePediatric" #expirePediatric="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="calendar" (click)="expirePediatric.toggle()" type="button">
                                        <i class="icon-calender"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!editModal" class="row">
                        <div class="col">
                            <eddy-upload-provider-document [docInfo]="'PALS'" [text]="'Upload PALS'">
                            </eddy-upload-provider-document>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="editModal" class="col">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-5 form-group">
                            <button type="button" *ngIf="editModal" (click)="closeModal()" class="go-back rectangle-button mb-0">
                                Cancel</button>
                        </div>
                        <div class="col-7 form-group">
                            <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i
                                    class="icon-check"></i> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>