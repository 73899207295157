import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProviderActions, ProviderActionTypes } from './provider.actions';
import { ProviderState } from 'src/app/view-models/provider-state';

const initialState: ProviderState = {
    Provider: null,
    Agents: [],
    Attachments: [],
    ProviderAttachments: {},
    CVInfo: null,
}
export const GetProviderFeatureState = createFeatureSelector<ProviderState>('Provider');
export const GetProviderInfo = createSelector(
    GetProviderFeatureState,
    state => state.Provider
);
export const GetAgents = createSelector(
    GetProviderFeatureState,
    state => state.Agents
);
export const GetAttachments = createSelector(
    GetProviderFeatureState,
    state => state.Attachments
);
export const GetProviderAttachments = createSelector(
    GetProviderFeatureState,
    state => state.ProviderAttachments
);
export const GetCVInfo = createSelector(
    GetProviderFeatureState,
    state => state.CVInfo
);

export function ProviderReducer(state = initialState, action: ProviderActions): ProviderState {
    switch (action.type) {
        case ProviderActionTypes.SetProvider: 
            return { 
                ...state,
                Provider: action.payload
            };
        case ProviderActionTypes.LoadProviderSuccess: 
            return { 
                ...state,
                Provider: action.payload
            };
        case ProviderActionTypes.LoadAgentsSuccess: 
            return { 
                ...state,
                Agents: action.payload
            };
        case ProviderActionTypes.LoadAattachmentsSuccess: 
            return { 
                ...state,
                Attachments: action.payload
            };
        case ProviderActionTypes.LoadProviderAattachmentsSuccess: 
            return { 
                ...state,
                ProviderAttachments: action.payload
            };
        case ProviderActionTypes.LoadCVInfoSuccess: 
            return { 
                ...state,
                CVInfo: action.payload
            };
        default: 
            return state;
    }
}