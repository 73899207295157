<form [formGroup]="personalInformationForm">
    <div class="row custom-form-design form-group">
        <div class="col-12">
            <div class="row form-group section">
                <div class="col">
                    <div class="row">
                        <div *ngIf="editModal" class="col-6 label"><b>Update CAQH Login/Profile</b></div>
                    </div>
                    <div class="form-group-item">
                        <div class="row form-group">
                            <div class="col-5 label">CAQH Number</div>
                            <div class="col-7 input-feild"><input type="text" class="form-control"
                                    placeholder="Enter CAQH Number" formControlName="caqhNumber"
                                    (change)="onChange($event)"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col-5 label">CAQH Username</div>
                            <div class="col-7 input-feild"><input type="text" class="form-control"
                                    Autocomplete="new-password" placeholder="Enter CAQH Username"
                                    formControlName="caqhUsername" (change)="onChange($event)"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col-5 label">CAQH Password</div>
                            <div class="col-7 input-feild"><input type="text" class="form-control"
                                    Autocomplete="new-password" placeholder="Enter CAQH Password"
                                    formControlName="caqhPassword" (change)="onChange($event)"></div>
                        </div>
                    </div>
                    <div *ngIf="!editModal" class="row">
                        <div class="col">
                            <eddy-upload-provider-document [docInfo]="'CAQH'" [text]="'Upload CAQH Profile'" (onChange)="onChange($event)">
                            </eddy-upload-provider-document>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="editModal" class="col">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-5 form-group">
                            <button type="button" *ngIf="editModal" (click)="closeModal()"
                                class="go-back rectangle-button mb-0">
                                Cancel</button>
                        </div>
                        <div class="col-7 form-group">
                            <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i
                                    class="icon-check"></i> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>