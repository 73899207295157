import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-mmr",
  templateUrl: "save.mmr.component.html",
  styleUrls: ["save.mmr.component.scss"],
})
export class SaveProviderMMRComponent extends SaveDocumentItem<SaveProviderMMRComponent> {
  dateFields = ['issuanceMMR'];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderMMRComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      issuanceMMR: [info ? info.issuanceMMR : "", Validators.required],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
