<form [formGroup]="hospitalForm" (ngSubmit)="AddNewHospital()">
    <div class="row  custom-form-design">
        <div class="col">
            <div class="row form-group">
                <div class="col">
                    <span class="custom-title">New Affiliation</span>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Affiliation Name <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter Affiliation Name" formControlName="name"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Affiliation Address <span class="text-danger">*</span></div>
                <div class="col-7 input-feild">
                    <div class="row form-group">
                        <div class="col">
                            <input class="form-control" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="AddressChange($event)"/>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Street Address 1" formControlName="streetAddress"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Street Address 2" formControlName="streetAddress2"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="Country Address" formControlName="countryAddress"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col"><input type="text" class="form-control" placeholder="City" formControlName="cityAddress"></div>
                        <div class="col"><input type="text" class="form-control" placeholder="State/Province" formControlName="stateAddress"></div>
                        <div class="col"><input type="text" class="form-control" placeholder="Zip" formControlName="zipAddress"></div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Affiliation Phone Number <span class="text-danger">*</span></div>
                <div class="col-7 input-feild">
                    <app-intl-tel-input [preferredCountries]="['us']" [phoneType]="'MOBILE'" [enablePlaceholder]="true" formControlName="phone"></app-intl-tel-input>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Affiliation Primary Email <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="email" class="form-control" placeholder="Enter Primary Email" formControlName="primaryEmail"></div>
            </div>
            <div class="row buttons">
                <div class="col-9"><button type="submit" class="rectangle-button green-button-two"><i class="icon-check"></i> Save</button></div>
                <div class="col-3"><button type="button" class="go-back" (click)="Cancel()">Cancel</button></div>
            </div>
        </div>
    </div>
</form>