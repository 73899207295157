import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonFormService } from 'src/app/services/common-form.service';
import { IOption } from 'src/app/view-models/ng-select-option';
import { SelectOptionService } from 'src/app/services/select-option.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PrimaryPractice } from 'src/app/view-models/primary-practice';
import { MidPractitioner } from 'src/app/view-models/mid-practitioner';
import { Partners } from 'src/app/view-models/partners';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { GoogleAddress } from 'src/app/view-models/google-address';
import { HandleAddressChange } from 'src/util';
import { NewFacilityComponent } from 'src/app/shared/components/new-facility/new-facility.component';
import { BaseCommonFormComponent } from 'src/app/shared/utils/base.common.form.component';

@Component({
  selector: 'eddy-primary-practice',
  templateUrl: 'primary-practice.component.html',
  styleUrls: ['primary-practice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavePrimaryPracticeComponent
  extends BaseCommonFormComponent<PrimaryPractice>
  implements OnInit, OnDestroy
{
  payeeSameMailing = false;
  payeeSamePrimary = false;
  payeeAsNew = false;
  primaryPracticeForm: FormGroup;
  primaryPracticeInfo: PrimaryPractice;
  midPractitioner: FormArray;
  partners: FormArray;
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  languagesOptions: Array<IOption> = this.selectOptionService.getLanguages();
  MD_DO_Options: Array<IOption> = this.selectOptionService.getMD_DO();
  DC_Options: Array<IOption> = this.selectOptionService.getDC();
  deletedPrimaryPracticeIds = [];
  officeDays: Array<string> = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  dateList = ['startPracticing', 'endPracticing'];
  selectedOfficeDays = [];
  ageGroupsTreated: Array<string> = [
    '0-6',
    '7-11',
    '12-18',
    '19-65',
    'Over 65',
    'All Ages',
    'Other',
  ];
  selectedAgeGroupsTreated = [];
  isOtherAge: boolean = false;

  practLimits: Array<string> = [
    'Male patients only',
    'Female patients only',
    'Minimum age for patients',
    'Maximum age for patient',
    'Other',
  ];
  selectedPractLimits = [];
  handiAccess: Array<string> = [
    'The building',
    'Parking lot',
    'Restrooms',
    'No handicapped access',
  ];
  selectedHandiAccess = [];
  servicesForDisabled: Array<string> = [
    'Text telephone',
    'American sign language',
    'Mental impairment services',
    'None',
  ];
  selectedServicesForDisabled = [];
  publicTransport: Array<string> = ['Bus', 'Subway', 'Regional Train', 'None'];
  selectedPublicTransport = [];
  radioServicesArray: Array<string> = ['X-ray', 'CT', 'UltraSound', 'Other'];
  selectedRadioServicesArray = [];
  offeredServices: Array<string> = [
    'EKG',
    'Asthma treatment',
    'Osteopathic manipulation',
    'Allergy skin testing',
    'IV hydration treatment',
    'Routine office gynecology',
    'Cardiac stress test',
    'Allergy Injections',
    'Drawing blood',
    'Pulmonary function testing',
    'Age appropriate immunizations',
    'Physical Therapy',
    'Flexible sigmoidoscopy',
    'Care of minor lacerations',
    'Tympanometry/audiometry screening',
  ];
  selectedOfferedServices = [];
  classAnesthesia: Array<string> = ['Local', 'Regional', 'General', 'Sedation'];
  selectedClassAnesthesia = [];
  phoneNumber: any;
  practicesCount: number = 0;
  dialCode: any;
  optin = { dialCode: '' };
  options: any = { position: ['bottom', 'right'], timeOut: 400 };
  arrayList = ['partners', 'midPractitioner'];
  multiSelectList = [
    'ageGroupsTreated',
    'classAnesthesia',
    'handiAccess',
    'officeDays',
    'practLimits',
    'publicTransport',
    'radioServicesArray',
    'offeredServices',
    'servicesForDisabled',
  ];
  ADDRESS_FIELDS = {
    street_number: 'addressStreet',
    route: 'addressApartment',
    locality: 'addressCity',
    administrative_area_level_1: 'addressState',
    postal_code: 'addressZip',
  };
  NAToProvider: boolean;
  coveragePlans: any[] = [];
  coveringPractitioners: any[] = [];
  isPrior: boolean;
  subscriptions = [];

  @Output('onChangePrimaryPractice')
  onChangeForm = new EventEmitter();
  @Output() onSave = new EventEmitter();
  constructor(
    private builder: FormBuilder,
    protected service: CommonFormService,
    private http: HttpClient,
    private selectOptionService: SelectOptionService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<SavePrimaryPracticeComponent>
  ) {
    super(service);
    if (dialogData) {
      const { info, isPrior } = dialogData;
      this.primaryPracticeInfo = info;
      this.isPrior = isPrior;
      this.PatchValue();
      this.editModal = true;
    }
  }

  ngOnInit() {
    this.CreateForm(this.isPrior);
    this.PatchValue();
  }

  CreateForm(isPrior = false): FormGroup {
    let formGroup = this.builder.group({
      id: [null],
      addressChange: [''],
      acceptExsisPatients: [false],
      acceptMedicaidPatients: [false],
      acceptMedicarePatients: [false],
      acceptNewPatients: [false],
      acceptPhyRefPatients: [false],
      addressCityPayee: [''],
      addressCityPrimPract: [{ value: '', disabled: false }],
      addressCountyPayee: [''],
      addressStatePayee: [''],
      addressStatePrimPract: [{ value: '', disabled: false }],
      addressStreet2Payee: [''],
      addressStreet2PrimPract: [{ value: '', disabled: false }],
      addressStreetPayee: [''],
      addressStreetPrimPract: [{ value: '', disabled: false }],
      addressZipPayee: [''],
      addressZipPrimPract: [{ value: '', disabled: false }],
      ageGroupsTreated: this.AddAgeGroupsTreated(),
      //this was required
      awardsReport: [null],
      billPrim: [''],
      billingSameManager: [false],
      credentialSameManager: [false],
      payeeSameMailing: [false],
      payeeSamePrimary: [false],
      payeeAsNew: [false],
      payeeAddress: [''],
      classAnesthesia: this.AddClassAnesthesia(),
      compNumber: [''],
      countyPract: [''],
      emailBillPrim: [''],
      emailOfficePrimPract: [{ value: '', disabled: false }, Validators.email],
      faxOfficePrimPract: [''],
      firstNameAdminAnesthesia: [''],
      firstNameBillPrim: [''],
      groupNPI: [''],
      grpTaxId: [null],
      handiAccess: this.AddHandiAccess(),
      indTaxId: [null],
      interpAvailable: [false],
      isADAAccess: [false],
      isAnesthesia: [false],
      isCarePatients: [false],
      isCompensation: [false],
      isContactPrimCredent: [false],
      isElecBill: [false],
      isLabServices: [false],
      isManagerSame: [false],
      isPracticing: [false, Validators.required],
      isPractLimits: [false],
      isSupervise: [false],
      isPrimaryPractise: [true, Validators.required],
      isRadiologyServices: ['false'],
      isRecvCorrespondance: [false],
      isUsePayee: [true],
      lastNameBillPrim: [''],
      managerEmail: ['', Validators.email],
      managerFirstName: [''],
      managerPhone: [''],
      //this was required
      membershipReport: [null],
      midPractitioner: this.builder.array([]),
      nameCertifProgram: [''],
      nonEngLang: [null],
      offeredServices: this.AddOfferedServices(),
      officeDays: this.AddOfficeDays(),
      officeNoPrimPract: [''],
      otherAgeGroups: [''],
      partners: this.builder.array([]),
      coveragePlans: this.builder.array([]),
      coveringPractitioners: this.builder.array([]),
      partnersAnthesia: [false],
      hasCoveragePlan: [false],
      hasCoveringPractitioners: [false],
      website: [''],
      PhoneAfterHours: [''],
      phoneBillPrim: [''],
      phoneCoverage: [false],
      physGroup1: ['', Validators.required],
      physGroup2: [''],
      practEIN: [''],
      practLimits: this.AddPractLimits(),
      practiceType: [''],
      primaryTaxId: [''],
      primCredentEmail: ['', Validators.email],
      primCredentFirstName: [''],
      primCredentLastName: [''],
      primCredentPhone: [''],
      publicTransport: this.AddPublicTransport(),
      radioServicesArray: this.AddRadioServicesArray(),
      servicesForDisabled: this.AddServicesForDisabled(),
      startPracticing: [null],
      timeEndOffice: [null],
      timeStartOffice: [null],
      typePhoneCoverage: [''],
      xrayCertifType: [''],
      sameForMailing: [true],
      mailingAddressStreet: [''],
      mailingAddressApartment: [''],
      mailingAddressCity: [''],
      mailingAddressZip: [''],
      mailingAddressState: [''],
      mailingAddressContactName: [''],
      mailingAddressFaxNumber: [''],
      mailingAddressEmail: [''],
      mondayTimeStartOffice: ['08:00'],
      mondayTimeEndOffice: ['17:00'],
      tuesdayTimeStartOffice: ['08:00'],
      tuesdayTimeEndOffice: ['17:00'],
      wednesdayTimeStartOffice: ['08:00'],
      wednesdayTimeEndOffice: ['17:00'],
      thursdayTimeStartOffice: ['08:00'],
      thursdayTimeEndOffice: ['17:00'],
      fridayTimeStartOffice: ['08:00'],
      fridayTimeEndOffice: ['17:00'],
      saturdayTimeStartOffice: ['08:00'],
      saturdayTimeEndOffice: ['17:00'],
      sundayTimeStartOffice: ['08:00'],
      sundayTimeEndOffice: ['17:00'],
      isPrior: [isPrior, Validators.required],
      endPracticing: [null],
      isPrimary: [!isPrior],
    });

    const iCareSubscribe = formGroup
      .get('isCarePatients')
      .valueChanges.subscribe((change) => {
        if (change) {
          if ((formGroup.get('midPractitioner') as FormArray).length == 0)
            (formGroup.get('midPractitioner') as FormArray).push(
              this.CreateMidPractitioner()
            );
        } else {
          formGroup.controls.midPractitioner = this.builder.array([]);
        }
        this.cd.markForCheck();
      });
    this.subscriptions.push(iCareSubscribe);

    const _partnerSubscribe = formGroup
      .get('partnersAnthesia')
      .valueChanges.subscribe((change) => {
        if (change) {
          (formGroup.get('partners') as FormArray).push(this.CreatePartners());
        } else {
          formGroup.controls.partners = this.builder.array([]);
        }
        this.cd.markForCheck();
      });
    this.subscriptions.push(_partnerSubscribe);

    this.primaryPracticeForm = formGroup;
    return formGroup;
  }

  onChange(event: any) {
    let primaryPracticeData = this.primaryPracticeForm.value;
    this.ConvertDate(primaryPracticeData);

    const isValid = this.primaryPracticeForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.primaryPracticeForm);
    this.onChangeForm.emit({ ...primaryPracticeData, isValid });
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveForm() {
    let primaryPracticeData = this.primaryPracticeForm.value;

    const isValid = this.primaryPracticeForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.primaryPracticeForm);

    this.BooleanList(primaryPracticeData);
    primaryPracticeData.isValid = this.primaryPracticeForm.valid;
    primaryPracticeData.nonEngLang &&
      (primaryPracticeData.nonEngLang = [primaryPracticeData.nonEngLang]);
    primaryPracticeData.payeeAsNew =
      primaryPracticeData.addressChange == 'payeeAsNew';
    primaryPracticeData.payeeSamePrimary =
      primaryPracticeData.addressChange == 'payeeSamePrimary';
    primaryPracticeData.payeeSameMailing =
      primaryPracticeData.addressChange == 'payeeSameMailing';
    this.ConvertDate(primaryPracticeData);

    this.onSave.emit(primaryPracticeData);
  }

  PatchValue() {
    let formGroup = this.CreateForm(this.isPrior);
    if (!this.primaryPracticeInfo) {
      return formGroup;
    } else {
      const elm = this.primaryPracticeInfo;
      for (let item of Object.keys(elm)) {
        if (
          [
            'isPrimaryPractise',
            'addressCityPrimPract',
            'addressStreetPrimPract',
            'addressStreet2PrimPract',
            'addressStatePrimPract',
            'addressZipPrimPract',
            // "officeNoPrimPract",
          ].includes(item)
        )
          continue;
        if (item === 'physGroup1' && elm['physGroup1']) {
          formGroup.controls.physGroup1.patchValue(
            typeof elm[item] == 'string' ? elm[item] : elm[item][0].display
          );
          formGroup.controls.addressStreetPrimPract.patchValue(
            elm.addressStreetPrimPract
          );
          formGroup.controls.addressStreet2PrimPract.patchValue(
            elm.addressStreet2PrimPract
          );
          formGroup.controls.addressCityPrimPract.patchValue(
            elm.addressCityPrimPract
          );
          formGroup.controls.addressStatePrimPract.patchValue(
            elm.addressStatePrimPract
          );
          formGroup.controls.addressZipPrimPract.patchValue(
            elm.addressZipPrimPract
          );
          // formGroup.controls.officeNoPrimPract.patchValue(
          //   elm.officeNoPrimPract
          // );
        }
        if (formGroup.get(item)) {
          if (
            this.arrayList.indexOf(item) < 0 &&
            this.multiSelectList.indexOf(item) < 0
          ) {
            if (item === 'startPracticing' || item === 'endPracticing') {
              formGroup
                .get(item)
                .patchValue(this.service.ConvertStringToDate(elm[item]));
            } else {
              formGroup.get(item).patchValue(elm[item]);
            }
          } else if (this.multiSelectList.indexOf(item) >= 0) {
            this.MultiSelectPatchValue(item, elm[item]);
          } else if (this.arrayList.indexOf(item) >= 0) {
            this.FormArrayPatchValue(item, elm[item]);
          }
        }
        if (
          ['payeeSameMailing', 'payeeSamePrimary', 'payeeAsNew'].includes(item)
        ) {
          let active = this.patchValueAddressChange(elm, item);
          active && formGroup.controls.addressChange.patchValue(active);
        }
        if (item == 'isRadiologyServices') {
          let val = false;
          if (elm.isRadiologyServices == 'true') val = true;
          formGroup.controls.isRadiologyServices.patchValue(val);
        }
        if (item === 'coveragePlans') {
          this.coveragePlans.push(elm.coveragePlans);
        }
        if (item === 'coveringPractitioners') {
          this.coveringPractitioners.push(elm.coveringPractitioners);
        }
      }
      this.primaryPracticeForm = formGroup;
    }
    return this.primaryPracticeForm;
  }

  patchValueAddressChange(data, key) {
    let activekey = '';
    switch (key) {
      case 'payeeSameMailing':
        if (data[key]) {
          activekey = 'payeeSameMailing';
        }
        break;
      case 'payeeSamePrimary':
        if (data[key]) {
          activekey = 'payeeSamePrimary';
        }
        break;
      case 'payeeAsNew':
        if (data[key]) {
          activekey = 'payeeAsNew';
        }
        break;
      default:
        break;
    }
    return activekey;
  }

  MultiSelectPatchValue(name: string, items: string[]) {
    switch (name) {
      case 'officeDays':
        this.MultiSelectItemSet(name, items, this.officeDays);
        break;
      case 'ageGroupsTreated':
        this.MultiSelectItemSet(name, items, this.ageGroupsTreated);
        break;
      case 'practLimits':
        this.MultiSelectItemSet(name, items, this.practLimits);
        break;
      case 'handiAccess':
        this.MultiSelectItemSet(name, items, this.handiAccess);
        break;
      case 'servicesForDisabled':
        this.MultiSelectItemSet(name, items, this.servicesForDisabled);
        break;
      case 'publicTransport':
        this.MultiSelectItemSet(name, items, this.publicTransport);
        break;
      case 'radioServicesArray':
        this.MultiSelectItemSet(name, items, this.radioServicesArray);
        break;
      case 'offeredServices':
        this.MultiSelectItemSet(name, items, this.offeredServices);
        break;
      case 'classAnesthesia':
        this.MultiSelectItemSet(name, items, this.classAnesthesia);
        break;
      default:
        break;
    }
    this.cd.markForCheck();
  }

  MultiSelectItemSet(name: string, items: string[], itemArray: string[]) {
    const array = [];
    itemArray.forEach((day, index) => {
      if (items.indexOf(day) >= 0) {
        array[index] = this.builder.control(true);
      } else {
        array[index] = this.builder.control(false);
      }
    });
    const formArray = this.builder.array(array);
    this.primaryPracticeForm.setControl(name, formArray);
    this.cd.markForCheck();
  }

  FormArrayPatchValue(name: string, array: any[]) {
    if (name === 'partners') {
      this.primaryPracticeForm.setControl(
        'partners',
        this.PartnersValue(array)
      );
    } else if (name === 'midPractitioner') {
      this.primaryPracticeForm.setControl(
        'midPractitioner',
        this.MidPractitionerValue(array)
      );
    }
  }
  // Mid Practitioner Form Array
  get midPractitionerArray() {
    return <FormArray>this.primaryPracticeForm.get('midPractitioner');
  }
  CreateMidPractitioner(): FormGroup {
    return this.builder.group({
      numCarePatients: [''],
      firstNameMidPractitioner: [''],
      middleNameMidPractitioner: [''],
      lastNameMidPractitioner: [''],
      typePractitioner: [''],
      licenseNOPractitioner: [''],
      statePractitioner: [null],
    });
  }

  MidPractitionerValue(informations: MidPractitioner[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach((info) => {
      formArray.push(
        this.builder.group({
          firstNameMidPractitioner: [info.firstNameMidPractitioner],
          middleNameMidPractitioner: [info.middleNameMidPractitioner],
          lastNameMidPractitioner: [info.lastNameMidPractitioner],
          typePractitioner: [info.typePractitioner],
          licenseNOPractitioner: [info.licenseNOPractitioner],
          statePractitioner: [info.statePractitioner],
        })
      );
    });
    return formArray;
  }

  // Partners Form Array
  get partnersArray() {
    return <FormArray>this.primaryPracticeForm.get('partners');
  }
  CreatePartners(): FormGroup {
    return this.builder.group({
      firstNamePartnerAnth: [''],
      lastNamePartnerAnth: [''],
      specCode1Partner1: [null],
      specCode2Partner1: [null],
      isCoverColleague: [false],
    });
  }
  PartnersValue(informations: Partners[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach((info) => {
      formArray.push(
        this.builder.group({
          firstNamePartnerAnth: [info.firstNamePartnerAnth],
          lastNamePartnerAnth: [info.lastNamePartnerAnth],
          specCode1Partner1: [info.specCode1Partner1],
          specCode2Partner1: [info.specCode2Partner1],
          isCoverColleague: [info.isCoverColleague],
        })
      );
      this.partners = formArray;
    });
    return formArray;
  }
  // Office Days FormArray
  get officeDaysArray() {
    return <FormArray>this.primaryPracticeForm.get('officeDays');
  }
  AddOfficeDays() {
    const array = this.officeDays.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  setDefaultValueToOfficeDay(indx, formGroupIndex: number, checked = true) {
    let defaultStartDate = null;
    let defaultEndDate = null;
    if (checked) {
      defaultStartDate = '08:00';
      // (this.primaryPracticeForm.controls
      //   .primaryPracticeData as FormArray)
      //   .at(formGroupIndex)
      //   .get("timeStartOffice").value;
      defaultEndDate = '17:00';
      // (this.primaryPracticeForm.controls
      //   .primaryPracticeData as FormArray)
      //   .at(formGroupIndex)
      //   .get("timeEndOffice").value
    }
    switch (indx) {
      case 0:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('mondayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('mondayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 1:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('tuesdayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('tuesdayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 2:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('wednesdayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('wednesdayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 3:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('thursdayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('thursdayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 5:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('saturdayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('saturdayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 4:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('fridayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('fridayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
      case 6:
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('sundayTimeStartOffice')
          .setValue(defaultStartDate);
        (this.primaryPracticeForm.controls.primaryPracticeData as FormArray)
          .at(formGroupIndex)
          .get('sundayTimeEndOffice')
          .setValue(defaultEndDate);
        break;
    }
  }
  GetSelectedOfficeDaysValue(formGroupIndex: number, officeDaysIndex) {
    let obj = { index: formGroupIndex, data: [] };
    let data = this.selectedOfficeDays.find((i) => i.index === formGroupIndex);
    let officeDays = (
      this.primaryPracticeForm.controls.primaryPracticeData as FormArray
    )
      .at(formGroupIndex)
      .get('officeDays') as FormArray;
    if (data) {
      officeDays.controls.forEach((control, index) => {
        if (control.value) {
          obj.data.push(this.officeDays[index]);
          if (index == officeDaysIndex)
            this.setDefaultValueToOfficeDay(index, formGroupIndex);
        } else if (!control.value && index == officeDaysIndex)
          this.setDefaultValueToOfficeDay(index, formGroupIndex, false);
      });
      data.data = obj.data;
    } else {
      officeDays.controls.forEach((control, index) => {
        if (control.value) {
          obj.data.push(this.officeDays[index]);
          if (index == officeDaysIndex)
            this.setDefaultValueToOfficeDay(index, formGroupIndex);
        } else if (!control.value && index == officeDaysIndex)
          this.setDefaultValueToOfficeDay(index, formGroupIndex, false);
      });
      this.selectedOfficeDays.push(obj);
    }
  }
  // Age Groups Treated FormArray
  get ageGroupsTreatedArray() {
    return <FormArray>this.primaryPracticeForm.get('ageGroupsTreated');
  }

  isOfficDay(day) {
    return false; // in order to remove work days and hours
    // let officeDays = (this.primaryPracticeForm.controls
    //   .primaryPracticeData as FormArray)
    //   .at(formGroupIndex)
    //   .get("officeDays") as FormArray;
    // let data = officeDays.value;
    // return data[day]
  }
  AddAgeGroupsTreated() {
    const array = this.ageGroupsTreated.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Pract Limits FormArray
  get practLimitsArray() {
    return <FormArray>this.primaryPracticeForm.get('practLimits');
  }
  AddPractLimits() {
    const array = this.practLimits.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  GetSelectedpractLimitsValue() {
    let obj = { data: [] };
    let data: any = this.selectedPractLimits;
    let practLimits = this.primaryPracticeForm.get('practLimits') as FormArray;

    practLimits.controls.forEach((control, index) => {
      if (control.value) {
        obj.data.push(this.practLimits[index]);
      }
    });
    if (data) {
      data.data = obj.data;
    } else {
      this.selectedPractLimits.push(obj);
    }
  }
  // Handi Access FormArray
  get handiAccessArray() {
    return <FormArray>this.primaryPracticeForm.get('handiAccess');
  }
  AddHandiAccess() {
    const array = this.handiAccess.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Services For Disabled FormArray
  get servicesForDisabledArray() {
    return <FormArray>this.primaryPracticeForm.get('servicesForDisabled');
  }
  AddServicesForDisabled() {
    const array = this.servicesForDisabled.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Public Transport FormArray
  get publicTransportArray() {
    return <FormArray>this.primaryPracticeForm.get('publicTransport');
  }
  AddPublicTransport() {
    const array = this.publicTransport.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Radio Services Array FormArray
  get radioServicesArrayArray() {
    return <FormArray>this.primaryPracticeForm.get('radioServicesArray');
  }
  AddRadioServicesArray() {
    const array = this.radioServicesArray.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Offered Services FormArray
  get offeredServicesArray() {
    return <FormArray>this.primaryPracticeForm.get('offeredServices');
  }
  AddOfferedServices() {
    const array = this.offeredServices.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  // Offered Services FormArray
  get classAnesthesiaArray() {
    return <FormArray>this.primaryPracticeForm.get('classAnesthesia');
  }
  AddClassAnesthesia() {
    const array = this.classAnesthesia.map((item) => {
      return this.builder.control(false);
    });
    return this.builder.array(array);
  }
  //  Search with tag-input selector
  public requestPracticeAutocomplete = (text: string) => {
    const url = `${environment.API_URL}practice-info/autocomplete?search=${text}`;
    return this.http.get(url).pipe(map((data) => data));
  };
  primAddressChange({ address_components = [] }): void {
    let addressInfo: GoogleAddress = HandleAddressChange(
      address_components,
      this.ADDRESS_FIELDS
    );
    this.primaryPracticeForm.patchValue({
      addressStreetPrimPract: addressInfo.addressStreet,
      addressCityPrimPract: addressInfo.addressCity,
      addressStatePrimPract: addressInfo.addressState,
      addressZipPrimPract: addressInfo.addressZip,
    });
  }
  AddressChange({ address_components = [], name }) {
    let addressInfo: GoogleAddress = HandleAddressChange(
      address_components,
      this.ADDRESS_FIELDS
    );

    this.primaryPracticeForm.patchValue({
      addressStreetPrimPract: addressInfo.addressStreet,
      addressApartmentPrimPract: addressInfo.addressApartment,
      addressCityPrimPract: addressInfo.addressCity,
      addressStatePrimPract: addressInfo.addressState,
      addressZipPrimPract: addressInfo.addressZip,
      physGroup1: name,
    });
    this.cd.markForCheck();
  }

  coveragePlansChanged(event: FormArray) {
    (this.primaryPracticeForm.controls.coveragePlans as FormArray) = event;
  }

  coveringPractitionersChanged(event: FormArray) {
    this.primaryPracticeForm.setControl('coveringPractitioners', event);
  }

  isPrimaryChange(event: any) {
    this.primaryPracticeForm.get('isPrimary').setValue(event);
    this.cd.markForCheck();
  }

  BooleanList(information: PrimaryPractice) {
    information.officeDays = this.ConvertBooleanToString(
      this.officeDays,
      information.officeDays
    );
    information.ageGroupsTreated = this.ConvertBooleanToString(
      this.ageGroupsTreated,
      information.ageGroupsTreated
    );
    information.practLimits = this.ConvertBooleanToString(
      this.practLimits,
      information.practLimits
    );
    information.handiAccess = this.ConvertBooleanToString(
      this.handiAccess,
      information.handiAccess
    );
    information.servicesForDisabled = this.ConvertBooleanToString(
      this.servicesForDisabled,
      information.servicesForDisabled
    );
    information.publicTransport = this.ConvertBooleanToString(
      this.publicTransport,
      information.publicTransport
    );
    information.radioServicesArray = this.ConvertBooleanToString(
      this.radioServicesArray,
      information.radioServicesArray
    );
    information.offeredServices = this.ConvertBooleanToString(
      this.offeredServices,
      information.offeredServices
    );
    information.classAnesthesia = this.ConvertBooleanToString(
      this.classAnesthesia,
      information.classAnesthesia
    );
  }
  ConvertBooleanToString(stringList: string[], booleanList: any[]): string[] {
    let array: string[] = [];
    booleanList.forEach((item, index) => {
      if (item === true) {
        array.push(stringList[index]);
      }
    });
    return array;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let subscribe of this.subscriptions) {
        subscribe.unsubscribe();
      }
    }
  }
}
