import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { CoveragePlan } from "src/app/view-models/coverage-plan";

@Component({
  selector: "primary-practice-coverage-plan",
  templateUrl: "coverage-plan.component.html",
  styleUrls: ["coverage-plan.component.scss"],
})
export class CoveragePlanComponent implements OnInit {
  @Input('userId') userId: string;
  coveragePlanForm: FormGroup;
  coveragePlanArray: FormArray;
  coveragePlanValue: CoveragePlan[];

  /** Input of Component - coveragePlans */
  @Input()
  get coveragePlans() {
    let coveragePlanArray = this.coveragePlanForm.controls
      .coveragePlanArray as FormArray;
    this.coveragePlanArray = coveragePlanArray;
    return this.coveragePlanArray.value;
  }

  @Output()
  coveragePlansChange = new EventEmitter();

  set coveragePlans(val) {
    if (val) {
      if (val.length > 0) {
        this.coveragePlanValue = val
        this.coveragePlanArray = this.CoveragePlanValue(val);
        this.coveragePlanForm = this.builder.group({
          coveragePlanArray: this.coveragePlanArray
        })
        this.coveragePlansChange.emit(this.coveragePlanArray);
      } else {
        // this.AddCoveragePlan();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If coveragePlans does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing coveragePlans
   */
  CreateForm() {
    this.coveragePlanArray = this.coveragePlanArray ? this.coveragePlanArray : this.builder.array([]);
    this.coveragePlanForm = this.builder.group({
      coveragePlanArray: this.coveragePlanArray
    });

    if (this.coveragePlanArray.length == 0)
      this.AddCoveragePlan();
    else this.CoveragePlanValue(this.coveragePlanValue)
  }

  // Coverage Plan Form Array
  get coveragePlan() {
    return <FormArray>this.coveragePlanArray;
  }

  /**
   * Create an empty form for a new coverage plan
   * @returns FormGroup
   */
  CreateCoveragePlan(info: CoveragePlan = null): FormGroup {
    let form = this.builder.group({
      admittingName: [info ? info.admittingName : ""],
      hospital: [info ? info.hospital : ""],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new coverage plan to form array
   */
  AddCoveragePlan(): void {
    let coveragePlanArray = this.coveragePlanForm.controls
      .coveragePlanArray as FormArray;
    coveragePlanArray.push(this.CreateCoveragePlan());

    this.coveragePlanArray = coveragePlanArray;
    this.coveragePlansChange.emit(this.coveragePlanArray);
  }

  /**
   * Remove a coverage plan from form array
   * @param index Index of the coverage plan to remove
   */
  RemoveCoveragePlan(index: number): void {
    let coveragePlanArray = this.coveragePlanForm.get("coveragePlanArray") as FormArray;

    if (coveragePlanArray.length > 1) {
      coveragePlanArray.removeAt(index);
    } else {
      coveragePlanArray = this.builder.array([]);
    }
    this.coveragePlanArray = coveragePlanArray;
    this.coveragePlansChange.emit(this.coveragePlanArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let coveragePlanArray = this.coveragePlanForm.controls
      .coveragePlanArray as FormArray;
    this.coveragePlanArray = coveragePlanArray;
    this.coveragePlansChange.emit(this.coveragePlanArray);
  }

  /**
   * Convert coverage plan array to form array
   * @param informations JSON array of coverage plans
   * @returns Array of FormGroup of coverage plans
   */
  CoveragePlanValue(informations: CoveragePlan[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          admittingName: [info.admittingName],
          hospital: [info.hospital],
        })
      );
    });
    return formArray;
  }

}
