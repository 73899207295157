import { Component, EventEmitter, Inject, Optional, Output } from "@angular/core";
import {
  FormBuilder,
  Validators
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { EditDocumentItem } from "src/app/shared/utils/editDocumentItem";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "eddy-provider-ACLS-documents",
  templateUrl: "ACLS.component.html",
  styleUrls: ["ACLS.component.scss"],
})
export class ACLSDocumentsComponent extends EditDocumentItem<ACLSDocumentsComponent> {
  isAcls = true;
  arrayList = [];
  dateList = [
    "aclsExpDate"
  ];

  @Output("onChangeACLS")
  onChangeForm = new EventEmitter();


  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() dialogRef: MatDialogRef<ACLSDocumentsComponent>
  ) {
    super(builder, service, dialogData, dialogRef)
  }

  CreateForm() {
    this.certificationForm = this.builder.group({
      isAcls: [true, Validators.required],
      numberACLS: ['', Validators.required],
      aclsExpDate: [null, Validators.required],
    });

    if (this.certificationInfo) {
      this.PatchValue(this.certificationInfo);
    }
  }

}
