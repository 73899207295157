import { Component, EventEmitter, Output, OnDestroy, Optional, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { EditDocumentItem } from "src/app/shared/utils/editDocumentItem";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Certification } from "src/app/view-models/certification";

@Component({
  selector: "eddy-provider-BLS-documents",
  templateUrl: "BLS.component.html",
  styleUrls: ["BLS.component.scss"],
})
export class BLSDocumentsComponent extends EditDocumentItem<BLSDocumentsComponent> implements OnDestroy {
  certificationInfo: Certification;
  isBLSExpire = false;
  arrayList = [];
  dateList = [
    "expireBLS"
  ];

  @Output("onChangeBLS")
  onChangeForm = new EventEmitter();
  subscriptions = [];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() dialogRef: MatDialogRef<BLSDocumentsComponent>
  ) {
    super(builder, service, dialogData, dialogRef)
  }

  CreateForm() {
    this.certificationForm = this.builder.group({
      isBLSExpire: [false, Validators.required],
      numberBLS: ['', this.requiredIfValidator(() => this.certificationForm.get("isBLSExpire").value)],
      expireBLS: [null, this.requiredIfValidator(() => this.certificationForm.get("isBLSExpire").value)],
    });

    const _subscribe = this.certificationForm.get("isBLSExpire").valueChanges.subscribe(change => {
      this.isBLSExpire = change;
      this.certificationForm.updateValueAndValidity();
      if (!change) {
        this.certificationForm.get("expireBLS").patchValue(null);
        this.certificationForm.get("numberBLS").patchValue(null);
        this.onChange(null);
      }
    });
    this.subscriptions.push(_subscribe)

    if (this.certificationInfo) {
      this.certificationInfo.isBLSExpire = this.editModal ? true :
        this.certificationInfo.isBLSExpire;
      this.PatchValue(this.certificationInfo);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let subscribe of this.subscriptions) {
        subscribe.unsubscribe();
      }
    }
  }

}
