import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Optional,
  Inject,
} from '@angular/core';
import { IOption } from 'src/app/view-models/ng-select-option';
import { SelectOptionService } from 'src/app/services/select-option.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonFormService } from 'src/app/services/common-form.service';
import { GoogleAddress } from 'src/app/view-models/google-address';
import { HandleAddressChange } from 'src/util';
import { PracticeInfo } from 'src/app/view-models/practice-info';
import { BaseCommonFormComponent } from 'src/app/shared/utils/base.common.form.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

const ADDRESS_FIELDS = {
  street_number: 'addressStreet',
  route: 'addressStreet2',
  locality: 'addressCity',
  administrative_area_level_1: 'addressState',
  postal_code: 'addressZip',
  country: 'addressCountry',
};

@Component({
  selector: 'eddy-work-history',
  templateUrl: 'work-history.component.html',
  styleUrls: ['work-history.component.scss'],
})
export class SaveWorkHistoryComponent
  extends BaseCommonFormComponent<PracticeInfo>
  implements OnInit, OnDestroy
{
  workHistoryForm: FormGroup;
  workHistoryInfo: PracticeInfo;
  GapInfo: FormArray;
  practiceInfo: FormArray;
  phoneNumber: any;
  dialCode: any;
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  arrayList = ['GapInfo', 'practiceInfo'];
  dateList = ['startDate', 'endDate', 'startingPract', 'endingPract'];
  optin = {
    dialCode: '',
  };
  options: any = {
    position: ['bottom', 'right'],
    timeOut: 400,
  };
  workGapDay = 30;
  subscriptions = [];

  @Output('onChangeWorkHistory')
  onChangeForm = new EventEmitter();
  @Output() onSave = new EventEmitter();

  constructor(
    private selectOptionService: SelectOptionService,
    private builder: FormBuilder,
    protected service: CommonFormService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<SaveWorkHistoryComponent>
  ) {
    super(service);
    if (dialogData) {
      const { info } = dialogData;
      this.workHistoryInfo = info;
      this.PatchValue();
      this.editModal = true;
    }
  }

  ngOnInit() {
    this.CreateForm();
    this.PatchValue();
  }

  onChange(event: any) {
    let workHistoryData = this.workHistoryForm.value;
    this.ConvertDate(workHistoryData);

    const isValid = this.workHistoryForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.workHistoryForm);
    this.onChangeForm.emit({ ...workHistoryData, isValid });
  }

  CreateForm(): FormGroup {
    const form = this.builder.group({
      namePract: ['', Validators.required],
      addressStreetPract: ['', Validators.required],
      addressStreet2Pract: [''],
      addressCityPract: ['', Validators.required],
      addressStatePract: ['', Validators.required],
      addressZipPract: ['', Validators.required],
      emailPract: ['', Validators.email],
      phonePract: [''],
      startingPract: ['', Validators.required],
      isCurrentlyHere: [true, Validators.required],
      // endingPract: ['',Validators.required],
      endingPract: [''],
      contFirstNamePract: [''],
      contLastNamePract: [''],
      leavingsReason: [''],
      position: ['', Validators.required],
      gapExplanation: [''],
      hasGap: [false],
    });
    this.workHistoryForm = form;
    return form;
  }

  PatchValue(): FormGroup {
    if (!this.workHistoryInfo) {
      this.CreateForm();
      return this.workHistoryForm;
    }

    if (this.workHistoryInfo.startingPract === 'Invalid date') {
      this.workHistoryInfo.startingPract = '';
    }
    if (this.workHistoryInfo.endingPract === 'Invalid date') {
      this.workHistoryInfo.endingPract = '';
    }
    this.workHistoryForm = this.builder.group({
      namePract: [this.workHistoryInfo.namePract, Validators.required],
      addressStreetPract: [
        this.workHistoryInfo.addressStreetPract,
        Validators.required,
      ],
      addressStreet2Pract: [this.workHistoryInfo.addressStreet2Pract],
      addressCityPract: [
        this.workHistoryInfo.addressCityPract,
        Validators.required,
      ],
      addressStatePract: [
        this.workHistoryInfo.addressStatePract,
        Validators.required,
      ],
      addressZipPract: [
        this.workHistoryInfo.addressZipPract,
        Validators.required,
      ],
      emailPract: [this.workHistoryInfo.emailPract, Validators.email],
      phonePract: [this.workHistoryInfo.phonePract],
      startingPract: [
        this.service.ConvertStringToDate(this.workHistoryInfo.startingPract),
        Validators.required,
      ],
      isCurrentlyHere: [
        this.workHistoryInfo.isCurrentlyHere,
        Validators.required,
      ],
      // endingPract: [this.service.ConvertStringToDate(this.workHistoryInfo.endingPract),Validators.required],
      endingPract: [
        this.service.ConvertStringToDate(this.workHistoryInfo.endingPract),
        this.requiredIfValidator(() => !this.workHistoryInfo.isCurrentlyHere),
      ],
      contFirstNamePract: [this.workHistoryInfo.contFirstNamePract],
      contLastNamePract: [this.workHistoryInfo.contLastNamePract],
      leavingsReason: [this.workHistoryInfo.leavingsReason],
      position: [this.workHistoryInfo.position, Validators.required],
      hasGap: [this.workHistoryInfo.hasGap],
      gapExplanation: [this.workHistoryInfo.gapExplanation],
    });
    return this.workHistoryForm;
  }

  // Google Address
  AddressChange({ address_components = [], name }) {
    let addressInfo: GoogleAddress = HandleAddressChange(
      address_components,
      ADDRESS_FIELDS
    );
    this.workHistoryForm.patchValue({
      addressStreetPract: addressInfo.addressStreet,
      addressCityPract: addressInfo.addressCity,
      addressStatePract: addressInfo.addressState,
      addressZipPract: addressInfo.addressZip,
      namePract: name,
    });
  }

  // in similar situations, add a second argument to below function to recognize intended formControlName, here we have just one formControl so it's not necessary.
  updateEndingPractValiditors() {
    const change = this.workHistoryForm.controls.isCurrentlyHere.value;
    if (!change) {
      this.workHistoryForm.controls.endingPract.setValidators(
        Validators.required
      );
    } else {
      this.workHistoryForm.controls.endingPract.patchValue(null);
      this.workHistoryForm.controls.endingPract.clearValidators();
      this.workHistoryForm.controls.endingPract.updateValueAndValidity();
    }
  }

  // Actions
  saveForm() {
    if (!this.workHistoryForm.valid) {
      this.service.ValidateAllFormFields(this.workHistoryForm);
    }

    let formInfo = this.workHistoryForm.getRawValue();
    formInfo = Object.assign(formInfo, { isValid: this.workHistoryForm.valid });
    this.ConvertDate(formInfo);

    this.onSave.emit(formInfo);
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let subscribe of this.subscriptions) {
        subscribe.unsubscribe();
      }
    }
  }
}
