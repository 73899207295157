import { EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";

export abstract class SaveDocumentItem<T> implements OnInit  {
  @Input('userId') userId: string;
  docForm: FormGroup;
  attachments: string[];
  isEditForm: boolean = false;
  info: any;
  dateFields: string[];

  @Output() onSave = new EventEmitter();

  constructor(
    protected builder: FormBuilder,
    protected service: CommonFormService,
    protected dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) _info,
  ) {
    if (!_info) this.CreateForm();
    else {
      this.info = _info.info;
      this.CreateForm(this.info);
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    this.CreateForm(this.info);
  }

  /**
   * Create an empty form for a new document item
   * @returns FormGroup
   */
  CreateForm(info: any = null): void {};

  onDateChange(e) {
    this.service.onManualDateEntry(e);
  }

  saveInfo() {
    let dateFields = this.docForm.value;
    this.service.ConvertDate(dateFields, this.dateFields);

    const isValid = this.docForm.valid;
    
    if (!isValid) this.service.ValidateAllFormFields(this.docForm);
    else this.onSave.emit(this.docForm.value);
  }

  closeModal() {
    this.dialogRef.close();
  }

}