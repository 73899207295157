import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonFormService } from 'src/app/services/common-form.service';
import { CarrInfo } from 'src/app/view-models/carr-info';
import { GoogleAddress } from 'src/app/view-models/google-address';
import { Insurance } from 'src/app/view-models/insurance';
import { HandleAddressChange } from 'src/util';

export const ADDRESS_FIELDS = {
  street_number: 'addressStreet',
  route: 'addressStreet2',
  locality: 'addressCity',
  administrative_area_level_1: 'addressState',
  postal_code: 'addressZip'
};
@Component({
  selector: 'eddy-provider-insurance-item',
  templateUrl: 'Insurance.component.html',
  styleUrls: ['Insurance.component.scss'],
})
export class ProviderInsuranceComponent implements OnInit {
  InsuranceForm: FormGroup;
  InsuranceInfo: Insurance;
  carrInfo: FormArray;
  dateList = ['startingCarr', 'endingCarr', 'retroactiveDate', 'dateOcc', 'dateclaimfilled', 'dateSettl'];

  /** Input of Component - Insurances */
  @Input()
  get Insurances() {
    let insuranceArray = this.InsuranceForm.controls
      .carrInfo as FormArray;
    this.carrInfo = insuranceArray;
    return this.carrInfo.value;
  }

  @Output()
  InsurancesChange = new EventEmitter();

  set Insurances(val) {
    if (val) {
      if (val.carrInfo && val.carrInfo.length > 0) {
        this.InsuranceInfo = val;
        this.carrInfo = this.Insurance_Value(val.carrInfo);
        this.InsuranceForm = this.builder.group({
          carrInfo: this.carrInfo
        });
        this.InsurancesChange.emit(this.carrInfo);
      } else {
        // this.AddInsurance();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  get carrInfoArray() {
    return <FormArray>(this.InsuranceForm as FormGroup).get('carrInfo');
  }

  CreateForm() {
    this.carrInfo = this.carrInfo ? this.carrInfo : this.builder.array([]);
    this.InsuranceForm = this.builder.group({
      carrInfo: this.carrInfo
    });

    if (this.carrInfo.length == 0)
      this.AddcarrInfo();
  }

  CreatecarrInfo() {
    const carrInfoFormGorup = this.builder.group({
      selfInsured: [''],
      OtherCoverage: [''],
      isCoverage: [false, Validators.required],
      policyCarr: ['', Validators.required],
      carrName: [''],
      addressStreetCarr: [''],
      addressStreet2Carr: [''],
      addressCityCarr: [''],
      addressStateCarr: [''],
      addressZipCarr: [''],
      phoneCarr: [''],
      startingCarr: ['', Validators.required],
      endingCarr: ['', Validators.required],
      typeCovCarr: [''],
      unlimitedCovCarr: [false],
      amountCovOcc: [''],
      amountCovAgg: [''],
      isTailCov: [false],
      retroactiveDate: [null],
      isMalpractice: [false, Validators.required],
      dateOcc: [null],
      dateclaimfilled: [null],
      dateSettl: [null],
      claimStatus: [''],
      insCarr: [''],
      allegDesc: [''],
      isPrimary: [true],
      id: [null],
      attachments: [[]]
    });
    return carrInfoFormGorup;
  }

  Insurance_Value(informations: CarrInfo[]): FormArray {
    const formArray = this.builder.array([]);
    informations && informations.length ? informations.forEach((info) => {
      formArray.push(
        this.builder.group({
          selfInsured: [info.selfInsured],
          OtherCoverage: [info.OtherCoverage],
          isCoverage: [info.isCoverage, Validators.required],
          policyCarr: [info.policyCarr, Validators.required],
          carrName: [info.carrName],
          addressStreetCarr: [info.addressStreetCarr],
          addressStreet2Carr: [info.addressStreet2Carr],
          addressCityCarr: [info.addressCityCarr],
          addressStateCarr: [info.addressStateCarr],
          addressZipCarr: [info.addressZipCarr],
          phoneCarr: [info.phoneCarr],
          startingCarr: [this.service.ConvertStringToDate(info.startingCarr), Validators.required],
          endingCarr: [this.service.ConvertStringToDate(info.endingCarr), Validators.required],
          typeCovCarr: [info.typeCovCarr],
          unlimitedCovCarr: [info.unlimitedCovCarr],
          amountCovOcc: [info.amountCovOcc],
          amountCovAgg: [info.amountCovAgg],
          isTailCov: [info.isTailCov],
          retroactiveDate: [this.service.ConvertStringToDate(info.retroactiveDate)],
          isMalpractice: [info.isMalpractice, Validators.required],
          dateOcc: [this.service.ConvertStringToDate(info.dateOcc)],
          dateclaimfilled: [this.service.ConvertStringToDate(info.dateclaimfilled)],
          dateSettl: [this.service.ConvertStringToDate(info.dateSettl)],
          claimStatus: [info.claimStatus],
          insCarr: [info.insCarr],
          allegDesc: [info.allegDesc],
          isPrimary: [info.isPrimary],
          id: [info.id],
          attachments: [info ? info.attachments : []],
        })
      )
    }) : formArray.push(this.CreatecarrInfo())
    return formArray;
  }

  getInsuranceAttachments(index: number) {
    return this.carrInfoArray.length && this.carrInfoArray.at(index).get("attachments").value ? this.carrInfoArray.at(index).get("attachments").value : [];
  }

  onChangeInsuranceAttachment(event, index: number) {
    (this.InsuranceForm.get('carrInfo') as FormArray).controls[index].get("attachments").setValue(event);
    this.onChange(event);
  }

  isPrimaryChange(event: any, index: number) {
    event && this.setAllPrimariesFalse();
    (this.InsuranceForm.get('carrInfo') as FormArray).at(index).get("isPrimary").setValue(event);
    this.onChange(null);
  }

  setAllPrimariesFalse() {
    for (var item in (this.InsuranceForm.get('carrInfo') as FormArray).controls) {
      (this.InsuranceForm.get('carrInfo') as FormArray).at(parseInt(item)).patchValue({
        isPrimary: false
      });
    }
  }

  /**
  * Add a new insurance to form array
  */
  AddcarrInfo(): void {
    let insuranceArray = this.InsuranceForm.controls
      .carrInfo as FormArray;
    insuranceArray.push(this.CreatecarrInfo());

    this.carrInfo = insuranceArray;
    this.InsurancesChange.emit(this.carrInfo);
  }

  /**
  * Remove a insurance from form array
  * @param index Index of the insurance to remove
  */
  RemovecarrInfo(index: number): void {
    let insuranceArray = this.InsuranceForm.get("carrInfo") as FormArray;

    if (insuranceArray.length > 1) {
      insuranceArray.removeAt(index);
    } else {
      insuranceArray = this.builder.array([]);
    }
    this.carrInfo = insuranceArray;
    this.InsurancesChange.emit(this.carrInfo);
  }

  onDateChange(e) {
    this.service.onManualDateEntry(e);
    this.onChange(e);
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let carrInfo = this.InsuranceForm.controls
      .carrInfo as FormArray;
    this.carrInfo = carrInfo;
    this.InsurancesChange.emit(this.carrInfo);
  }

  // Google Address Search
  AddressChange({ address_components = [], name }, index: number) {
    let addressInfo: GoogleAddress = HandleAddressChange(address_components, ADDRESS_FIELDS);

    (this.InsuranceForm.controls.carrInfo as FormArray).at(index).patchValue({
      carrName: name,
      addressStreetCarr: addressInfo.addressStreet,
      addressCityCarr: addressInfo.addressCity,
      addressStateCarr: addressInfo.addressState,
      addressZipCarr: addressInfo.addressZip,
    })
    this.onChange(null);
  }

  trackByFn(index: number, item: any) {
    return item.value.id ? item.value.id : `${item.value.isPrimary ? 'Active': ''}${index}`
  }

}