import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-save-medicare",
  templateUrl: "save.medicare.component.html",
  styleUrls: ["save.medicare.component.scss"],
})
export class SaveProviderMedicareComponent extends SaveDocumentItem<SaveProviderMedicareComponent> {
  dateFields = ['issuanceMedicare', 'expireIndMedicare'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderMedicareComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      stateIndMedicare: [info ? info.stateIndMedicare : null, Validators.required],
      numIndMedicare: [info ? info.numIndMedicare : "", Validators.required],
      issuanceMedicare: [info ? this.service.ConvertStringToDate(info.issuanceMedicare) : "", Validators.required],
      expireIndMedicare: [info ? this.service.ConvertStringToDate(info.expireIndMedicare) : null, Validators.required],
      attachments: [info ? info.attachments : []],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    return this.docForm;
  }
}
