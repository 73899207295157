import { Component, OnInit, forwardRef, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, OnChanges {

  phone;
  dialCode = '1';
  options = { initialCountry: 'us', autoHideDialCode: true };
  telInputObj;

  propagateChange = (_: any) => { };

  writeValue(obj: any): void {
    if (obj) {
      let input = ''
      input = obj.split(' ');
      if (input.length > 1) {
        this.dialCode = input[0];
        this.phone = input[1];
      } else {
        this.phone = input[0];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void { }

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnChanges(changes): void {
    this.propagateChange(this.phone);
  }

  hasError(event) { }

  getPhone(event) {
  }
  
  telInputObject(event) {
    this.telInputObj = event;
    if (!this.dialCode) {
      this.dialCode = event.s.dialCode;
    } else {
      setTimeout(() => {
        let code = this.telInputObj.p.filter(i => i.dialCode == this.dialCode).sort(function(a, b) { return a.priority - b.priority; })[0];
        
        if(code) {
          this.telInputObj.setCountry(code.iso2);
        }else {
          this.telInputObj.setCountry('us');

        }
        if(this.changeDetectorRef && !this.changeDetectorRef['destroyed']) 
          this.changeDetectorRef.detectChanges();
      }, 200);
    }
  }


  onCountryChange(event) {
    this.dialCode = event.dialCode;
    this.propagateChange(this.dialCode+ ' ' +this.phone);
  }

  onChange(event) {
    this.propagateChange(this.dialCode+ ' ' +this.phone);
  }
}
