import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-save-dea",
  templateUrl: "save.dea.component.html",
  styleUrls: ["save.dea.component.scss"],
})
export class SaveProviderDEAComponent extends SaveDocumentItem<SaveProviderDEAComponent> {
  dateFields = ['issuanceDEA', 'expireDEA'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  drugSchedulesOptions: Array<IOption> = this.selectOptionService.getDrugSchedules();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderDEAComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      deaNum: [info ? info.deaNum : "", Validators.required],
      deaXWaiver: [info ? info.deaXWaiver : ""],
      issuanceStateDEA: [info ? info.issuanceStateDEA : ""],
      issuanceDEA: [info ? this.service.ConvertStringToDate(info.issuanceDEA) : "", Validators.required],
      isDeaWorkingInState: [info ? info.isDeaWorkingInState : true],
      expireDEA: [info ? this.service.ConvertStringToDate(info.expireDEA) : null, Validators.required],
      stateDEA: [info ? info.stateDEA : "", Validators.required],
      deaDrugSchedule: [info ? info.deaDrugSchedule : []],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    return this.docForm;
  }

  checkDEADrugSchedule(item: string) {
    return this.docForm &&
      this.docForm.get("deaDrugSchedule").value &&
      this.docForm.get("deaDrugSchedule").value.indexOf(item) > -1 ?
      true : false;
  }

  onChangeDEADrugSchedule(item: string) {
    let drugSchedules: string[] = this.docForm.get("deaDrugSchedule").value;
    if (!drugSchedules) drugSchedules = [];
    if (drugSchedules.indexOf(item) <= -1) drugSchedules.push(item);
    else drugSchedules.splice(drugSchedules.indexOf(item), 1);
    this.docForm.get("deaDrugSchedule").setValue(drugSchedules);
  }

  isPrimaryChange(event: any) {
    this.docForm.get("isPrimary").setValue(event);
  }
}
