<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col-12">
              <a [href]="pdfSrc" class="download-btn" target="_blank" download>Download</a>
            </div>
            <div class="col-12" *ngIf="data.isHasFlag">
              <button class="button green-button-two rectangle-button m-2"
                      [class.pink-button]="data.runFlag"
                      style="width:fit-content; float: right;"
                      (click)="setFlag()" >

                {{data.runFlag?'Remove':'Set'}} Flag

              </button>
            </div>
        </div>
        <pdf-viewer [src]="pdfSrc" [render-text]="true" style="display: block;"></pdf-viewer>
    </nb-card-body>
</nb-card>
