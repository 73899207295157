<form [formGroup]="task" (ngSubmit)="CreateTask()">
    <div class="row form-group">
        <div class="col">
            <div class="title">
                {{ data.status === 'new' ? 'New Task' : 'Update Task' }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col custom-form-design">
            <div class="row form-group">
                <div class="col-5 label">Task Title <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="text" class="form-control"  placeholder="Enter task title" formControlName="title"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Task Date <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy"
                        name="task_date" placement="bottom" ngbDatepicker type="date" (keyup)="onDateChange($event)" formControlName="task_date" #task_date="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="task_date.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Task Time <span class="text-danger">*</span></div>
                <div class="col-7 input-feild"><input type="time" class="form-control"  placeholder="Enter task title" formControlName="task_time"></div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Task Description</div>
                <div class="col-7 input-feild">
                    <textarea type="text" class="form-control" formControlName="description" id="description"
                    placeholder="Enter task description" rows="5"></textarea>
                </div>
            </div>
            <div class="row" style="padding-top: 50px;">
                <div class="col">
                    <button *ngIf="data.status === 'new'" [disabled]="!task.valid" type="submit" class="rectangle-button green-button-two">Create Task</button>
                    <button *ngIf="data.status === 'update'"  [disabled]="!task.valid" type="button" (click)="UpdateTask()" class="rectangle-button green-button-two">Update Task</button>
                </div>
            </div>
        </div>
    </div>
</form>