<form [formGroup]="docForm">
    <!-- Start Visa FormArray -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col-5 label">Visa number</div>
                <div class="col-7 input-feild">
                    <input type="text" class="form-control" placeholder="Enter Visa Number" formControlName="visaNum">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Issue date of visa</div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="issuanceVisa" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)" formControlName="issuanceVisa"
                        #issuanceVisa="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="issuanceVisa.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration date of visa <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="expireVisa" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)" formControlName="expireVisa"
                        #expireVisa="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="expireVisa.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End Visa FormArray -->
</form>