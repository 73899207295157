<div class="intl-tel-input allow-dropdown w-100">
  <div class="flag-container" dropdown [isDisabled]="disabled" [ngClass]="{'disabled': disabled}">
    <div class="selected-flag dropdown-toggle" dropdownToggle>
      <div class="iti-flag" [ngClass]="selectedCountry.flagClass"></div>
      <div class="iti-arrow"></div>
    </div>
    <ul class="country-list dropdown-menu" *dropdownMenu>
      <li class="country" *ngFor="let country of preferredCountriesInDropDown" (click)="onCountrySelect(country, focusable)">
        <div class="flag-box">
          <div class="iti-flag" [ngClass]="country.flagClass"></div>
        </div>
        <span class="country-name">{{country.name}}</span>
        <span class="dial-code">+{{country.dialCode}}</span>
      </li>
      <li class="divider"></li>
      <li class="country" *ngFor="let country of allCountries" (click)="onCountrySelect(country, focusable)">
        <div class="flag-box">
          <div class="iti-flag" [ngClass]="country.flagClass"></div>
        </div>
        <span class="country-name">{{country.name}}</span>
        <span class="dial-code">+{{country.dialCode}}</span>
      </li>
    </ul>
  </div>
  <input type="text" id="phone" class="form-control"
         autocomplete="off"
         (keypress)="onInputKeyPress($event)"
         [(ngModel)]="phoneNumber"
         (input)="onPhoneNumberChange()"
         [disabled]="disabled"
         [mask]="getCountryMask()"
         [placeholder]="selectedCountry.placeHolder" #focusable id="focusable">
</div>
