import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CommonFormStatus } from '../view-models/common-form-status';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PersonalInformation } from '../view-models/personal-information';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DateModel, isDateModel } from '../view-models/date-model';
import { IOption } from '../view-models/ng-select-option';
import { DisclosureQuestions } from '../view-models/DisclosureQuestions';
import { EducationalInfo } from '../view-models/educational-info';
import { Training } from '../view-models/training';
import { Certification } from '../view-models/certification';
import { Specialties } from '../view-models/specialities';
import { CurrentPrivilege } from '../view-models/current-privilege';
import { PrimaryPractice } from '../view-models/primary-practice';
import { WorkHistory } from '../view-models/work-history';
import { Insurance } from '../view-models/insurance';
import { PrimaryPracticePost } from '../view-models/primary-practicepost';
import { MilitaryService } from '../view-models/military-service';
import { Reference } from '../view-models/reference';
import {
  ALL_BOARDS,
  ALLIED_PROVIDER_TYPE,
  DC,
  DDS_DMD,
  MD_DO,
  MD_DPM_MERGED,
  COMMON_FORMS_STEPS,
} from '../constants';
import { DateItems, DateItemsModel } from '../view-models/date-items';
import { Router } from '@angular/router';
@Injectable()
export class CommonFormService {
  constructor(private http: HttpClient) {}
  GetCommonFormSummary(): Observable<CommonFormStatus> {
    return this.http.get(`${environment.API_URL}common-form-summary`).pipe(
      map((summary: CommonFormStatus) => {
        return summary;
      })
    );
  }
  GetPersonalInfo(): Observable<PersonalInformation> {
    return this.http.get(`${environment.API_URL}personal-info`).pipe(
      map((info: PersonalInformation) => {
        return info;
      })
    );
  }
  SavePersonalInfo(info: PersonalInformation): Observable<any> {
    return this.http.post(`${environment.API_URL}personal-info`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderPersonalInfo(
    info: PersonalInformation,
    userId: string
  ): Observable<any> {
    return this.http
      .post(`${environment.API_URL}personal-info/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftPersonalInfo(info: PersonalInformation): Observable<any> {
    return this.http.put(`${environment.API_URL}personal-info`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetEducationalInfo(): Observable<EducationalInfo> {
    return this.http.get(`${environment.API_URL}educational-info`).pipe(
      map((educationalInfo: EducationalInfo) => {
        return educationalInfo;
      }),
      catchError((err, caught) => {
        let rd: EducationalInfo = {
          isUG: false,
          haveProSchool: false,
          nameUG: null,
          typeUG: null,
          isSchoolUG: false,
          isSchoolPG: false,
          addressStreetUG: null,
          addressStreet2UG: null,
          newUGSchool: null,
          addressCityUG: null,
          addressStateUG: null,
          addressCountryUG: null,
          addressZipUG: null,
          phoneUG: null,
          startDateUG: null,
          endDateUG: null,
          isDegreeUG: false,
          degreeUG: null,
          profEduUG: false,
          isAddUG: false,
          newPGSchool: null,
          additionalUGInfo: null,
          honorsAddUG: null,
          addHonorAddUG: null,
          codeUsPG: null,
          codeCanadaPG: null,
          addressStreetPG: null,
          addressStreet2PG: null,
          additionalProfInfo: null,
          addressCityPG: null,
          addressStatePG: null,
          addressCountryPG: null,
          addressZipPG: null,
          phonePG: null,
          typePG: null,
          isHonour: false,
          startDatePG: null,
          endDatePG: null,
          isDegreePG: false,
          degreePG: null,
          isAddPG: false,
          isHS: false,
          nameHS: null,
          addressStreetHS: null,
          addressStreet2HS: null,
          addressCityHS: null,
          addressStateHS: null,
          addressZipHS: null,
          addressCountryHS: null,
          startDateHS: null,
          endDateHS: null,
          isDegreeHS: false,
          degreeHS: null,
        };
        return of(rd);
      })
    );
  }
  SaveEducationalInfo(info: EducationalInfo): Observable<any> {
    return this.http.post(`${environment.API_URL}educational-info`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderEducationalInfo(
    info: EducationalInfo,
    userId: string
  ): Observable<any> {
    return this.http
      .post(`${environment.API_URL}educational-info/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftEducationalInfo(info: EducationalInfo): Observable<any> {
    return this.http.put(`${environment.API_URL}educational-info`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetTraining(): Observable<Training> {
    return this.http.get(`${environment.API_URL}training`).pipe(
      map((training: Training) => {
        return training;
      }),
      catchError((err, caught) => {
        let rd: Training = {
          numInst: null,
          trainingData: null,
          id: null,
          NAToProvider: false,
        };
        return of(rd);
      })
    );
  }
  SaveTraining(info: Training): Observable<any> {
    return this.http.post(`${environment.API_URL}training`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderTraining(info: Training, userId: string): Observable<any> {
    return this.http
      .post(`${environment.API_URL}training/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftTraining(info: Training): Observable<any> {
    return this.http.put(`${environment.API_URL}training`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetCertification(): Observable<Certification> {
    return this.http.get(`${environment.API_URL}certification`).pipe(
      map((certification: Certification) => {
        return certification;
      }),
      catchError((err, caught) => {
        let rd: Certification = {
          // status:null,
          cdsInfo: null,
          compNumber: null,
          deaInfo: null,
          ecfmgNo: null,
          expireABLS: null,
          expireAdvCPR: null,
          expireAdvOB: null,
          expireAdvTraum: null,
          expireBLS: null,
          expireNeontal: null,
          expireNRP: null,
          expireICC: null,
          expirePediatric: null,
          groupMedicadeNo: null,
          GroupMedicareNo: null,
          id: null,
          isABLS: false,
          isAdvOB: false,
          isAdvTrauma: false,
          isBLSExpire: false,
          isCDSLicense: false,
          isDEALicense: false,
          isECFMG: false,
          isExpireAdvCPR: false,
          isGroupMedicade: false,
          isGroupMedicare: false,
          isMedicade: false,
          isMedicare: false,
          isNeontal: false,
          isNML: false,
          isNRP: false,
          isICC: false,
          isPediatric: false,
          isStateLicense: false,
          issuanceECFMG: null,
          licenseInfo: null,
          medicareNo: null,
          NLSNum: null,
          stateMedicade: null,
          numberABLS: '',
          numberACLS: '',
          numberALSO: '',
          numberATLS: '',
          numberBLS: '',
          numberNALS: '',
          numberNRP: '',
          numberPALS: '',
          numberICC: '',
          countryECFMG: '',
        };
        return of(rd);
      })
    );
  }
  SaveCertification(info: Certification): Observable<any> {
    return this.http.post(`${environment.API_URL}certification`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveDraftCertification(info: Certification): Observable<any> {
    return this.http.put(`${environment.API_URL}certification`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetSpecialities(): Observable<Specialties> {
    return this.http.get(`${environment.API_URL}specialities`).pipe(
      map((specialities: Specialties) => {
        return specialities;
      }),
      catchError((err, caught) => {
        let rd: Specialties = {
          specialityData: null,
          id: null,
          NAToProvider: false,
        };
        return of(rd);
      })
    );
  }
  SaveSpecialities(info: Specialties): Observable<any> {
    return this.http.post(`${environment.API_URL}specialities`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderSpecialty(info: Specialties, userId: string): Observable<any> {
    return this.http
      .post(`${environment.API_URL}specialities/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftSpecialities(info: Specialties): Observable<any> {
    return this.http.put(`${environment.API_URL}specialities`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetCurrentPrivilege(): Observable<CurrentPrivilege> {
    return this.http.get(`${environment.API_URL}current-privileges`).pipe(
      map((currentPrivilege: CurrentPrivilege) => {
        return currentPrivilege;
      }),
      catchError((err, caught) => {
        let rd: CurrentPrivilege = {
          hospInfo: null,
          id: null,
          hospPrivilages: false,
          privilageType: null,
          admittingAgreementFName: null,
          admittingAgreementLName: null,
          admittingAgreementGName: null,
          active: null,
          historical: null,
        };
        return of(rd);
      })
    );
  }
  SaveCurrentPrivilege(info: CurrentPrivilege): Observable<any> {
    return this.http
      .post(`${environment.API_URL}current-privileges`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftCurrentPrivilege(info: CurrentPrivilege): Observable<any> {
    return this.http.put(`${environment.API_URL}current-privileges`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetPrimaryPractices(): Observable<PrimaryPractice[]> {
    return this.http.get(`${environment.API_URL}primary-practice/getAll`).pipe(
      map((primaryPractice: PrimaryPractice[]) => {
        return primaryPractice;
      }),
      catchError((err, caught) => {
        let rd: PrimaryPractice[] = null;
        return of(rd);
      })
    );
  }
  SavePrimaryPractice(info: PrimaryPracticePost): Observable<any> {
    return this.http
      .post(`${environment.API_URL}primary-practice/savePrimaryPractices`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveProviderPrimaryPractice(
    info: PrimaryPractice,
    userId: string
  ): Observable<any> {
    return this.http
      .post(`${environment.API_URL}primary-practice/save/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  DeleteProviderPrimaryPractice(info: any, userId: string): Observable<any> {
    return this.http
      .post(`${environment.API_URL}primary-practice/delete/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftPrimaryPractice(info: PrimaryPracticePost): Observable<any> {
    return this.http
      .put(`${environment.API_URL}primary-practice/savePrimaryPractices`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  GetWorkHistory(): Observable<WorkHistory> {
    return this.http.get(`${environment.API_URL}work-history`).pipe(
      map((workHistory: WorkHistory) => {
        return workHistory;
      }),
      catchError((err, caught) => {
        let rd: WorkHistory = {
          GapInfo: null,
          isGap: false,
          practiceInfo: null,
          additionalWorkInfo: false,
        };
        return of(rd);
      })
    );
  }
  SaveWorkHistory(info: WorkHistory): Observable<any> {
    return this.http.post(`${environment.API_URL}work-history`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderWorkHistory(info: WorkHistory, providerId): Observable<any> {
    return this.http
      .post(`${environment.API_URL}work-history/${providerId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftWorkHistory(info: WorkHistory): Observable<any> {
    return this.http.put(`${environment.API_URL}work-history`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetInsurance(): Observable<Insurance> {
    return this.http.get(`${environment.API_URL}liability-ins`).pipe(
      map((insurance: Insurance) => {
        return insurance;
      }),
      catchError((err, caught) => {
        let rd: Insurance = { carrInfo: null, id: null };
        return of(rd);
      })
    );
  }
  SaveInsurance(info: Insurance): Observable<any> {
    return this.http.post(`${environment.API_URL}liability-ins`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveProviderInsurance(info: Insurance, userId: string): Observable<any> {
    return this.http
      .post(`${environment.API_URL}liability-ins/${userId}`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftInsurance(info: Insurance): Observable<any> {
    return this.http.put(`${environment.API_URL}liability-ins`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetMilitaryService(): Observable<MilitaryService[]> {
    return this.http.get(`${environment.API_URL}military-service`).pipe(
      map((military: MilitaryService[]) => {
        return military;
      }),
      catchError((err, caught) => {
        let rd: MilitaryService[] = [];
        return of(rd);
      })
    );
  }
  SaveMilitaryService(info: any): Observable<any> {
    return this.http.post(`${environment.API_URL}military-service`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  SaveDraftMilitaryService(info: any): Observable<any> {
    return this.http.put(`${environment.API_URL}military-service`, info).pipe(
      map((info: any) => {
        return info;
      })
    );
  }
  GetProfessionalReference(): Observable<Reference[]> {
    return this.http.get(`${environment.API_URL}peer-references`).pipe(
      map((reference: Reference[]) => {
        return reference;
      }),
      catchError((err, caught) => {
        let rd: Reference[] = [];
        return of(rd);
      })
    );
  }
  SaveProfessionalReference(info: any): Observable<any> {
    return this.http
      .post(`${environment.API_URL}peer-references/save-references`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftProfessionalReference(info: any): Observable<any> {
    return this.http
      .put(`${environment.API_URL}peer-references/save-references`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  GetDisclosureQuestions(): Observable<DisclosureQuestions> {
    return this.http.get(`${environment.API_URL}disclosure-questions`).pipe(
      map((formData: DisclosureQuestions) => {
        return formData;
      }),
      catchError((err, caught) => {
        let rd: DisclosureQuestions = {
          isFined: null,
          noteFine: null,
          isChallenged: null,
          noteChallenged: null,
          isClinicalPrivileges: null,
          noteClinicalPrivileges: null,
          isSurrendered: null,
          noteSurrendered: null,
          isTerminated: null,
          noteTerminated: null,
          isPlaced: null,
          notePlaced: null,
          isInvestigation: null,
          noteInvestigation: null,
          isBoardCertification: null,
          noteBoardCertification: null,
          isReCertify: null,
          noteReCertify: null,
          isFederalDEA: null,
          noteFederalDEA: null,
          isDisciplined: null,
          noteDisciplined: null,
          hasSubjectInvestigation: null,
          noteSubjectInvestigation: null,
          hasInformation: null,
          noteInformation: null,
          isSanctions: null,
          noteSanctions: null,
          isConvicted: null,
          noteConvited: null,
          isInvestigated: null,
          noteInvestigated: null,
          hasProfessionalLiability: null,
          noteProfessionalLiability: null,
          hasAssessed: null,
          noteAssessed: null,
          hasConvictedPled: null,
          noteConvictedPled: null,
          hasConvictedPledTenYears: null,
          noteConvictedPledTenYears: null,
          hasCourtMartialed: null,
          noteCourtMartialed: null,
          hasCurrentlyEngaged: null,
          noteCurrentlyEngaged: null,
          hasChemicalSubstance: null,
          noteChemicalSubstance: null,
          isBelievePose: null,
          noteBelievePose: null,
          isUnableFunctions: null,
          noteUnableFunctions: null,
          hasLiabilityPending: null,
          liabilityPending: null,
          isValid: null,
          id: null,
        };
        return of(rd);
      })
    );
  }
  SaveDisclosureQuestions(info: DisclosureQuestions): Observable<any> {
    return this.http
      .post(`${environment.API_URL}disclosure-questions`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  SaveDraftDisclosureQuestions(info: DisclosureQuestions): Observable<any> {
    return this.http
      .put(`${environment.API_URL}disclosure-questions`, info)
      .pipe(
        map((info: any) => {
          return info;
        })
      );
  }
  GetDepartments(): Observable<Array<IOption>> {
    return this.http.get(`${environment.API_URL}departments`).pipe(
      map((departments: Array<IOption>) => {
        return departments;
      })
    );
  }
  SearchProvider(lastName: string = ''): Observable<any> {
    return this.http
      .get(`${environment.API_URL}users/search-providers?search=${lastName}`)
      .pipe(
        map((providers: Array<any>) => {
          return providers;
        })
      );
  }
  ConvertStringToDate(date: any): DateModel {
    if (date && date !== 'Invalid date') {
      let dateT = new Date(date);
      let newDate = moment(date)
        //remove locale
        .format('YYYY-MM-DD');
      let inputDate: DateModel = {
        year: Number(dateT.toISOString().substr(0, 4)),
        month: Number(dateT.toISOString().substr(5, 2)),
        day: Number(dateT.toISOString().substr(8, 2)),
      };
      return inputDate;
    }
    return;
  }
  ConvertDate(info: any, dateList: string[] = [], arrayList: string[] = []) {
    for (let item of Object.keys(info)) {
      if (arrayList.indexOf(item) >= 0) {
        // Check Date Time in Array
        for (let arrayItem of info[item]) {
          for (let key of Object.keys(arrayItem)) {
            if (dateList.indexOf(key) >= 0) {
              arrayItem[key] = this.ConvertDateToString(arrayItem[key]);
            }
          }
        }
      } else {
        if (dateList.indexOf(item) >= 0) {
          info[item] = this.ConvertDateToString(info[item]);
        }
      }
    }
  }
  ConvertDateToString(date: DateModel) {
    if (typeof date === 'string') return date;

    if (date && isDateModel(date))
      return moment(
        `${date.year}-${date.month}-${date.day}`,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');

    return;
  }
  ValidateAllFormFields(formGroup: FormGroup) {
    if (formGroup.controls) {
      Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.ValidateAllFormFields(control);
        } else if (control instanceof FormArray) {
          this.ValidateFormArray(control);
        }
      });
    }
  }
  ValidateFormArray(formArray: FormArray) {
    formArray.controls.forEach((item: FormGroup, index) => {
      this.ValidateAllFormFields(item);
    });
  }
  EnableFormArray(formArray: FormArray) {
    for (let item of formArray.controls) {
      item.enable();
    }
  }

  onDateChange(e) {
    e.target.value.split('-')[0] > 1900
      ? Array.from(document.querySelectorAll('input')).forEach((input) =>
          input.blur()
        )
      : null;
  }

  onManualDateEntry(e) {
    if (!e) return;
    if (isDateModel(e)) {
      if (`${e.year}`.length == 4) return `${e.year}-${e.month}-${e.year}`;
    } else {
      const parsedDate = e.target.value.trim().split('/');
      if (parsedDate.length === 3) {
        const year = parsedDate[2];
        const month = parsedDate[0];
        const day = parsedDate[1];
        if (year.length === 4 && month.length === 2 && day.length === 2) {
          return year + '-' + month + '-' + day; // Full date object
        }
      }
    }
  }

  getTaxonomyList() {
    return this.http.get(`${environment.API_URL}taxonomy`).pipe(
      map((result) => {
        return result;
      })
    );
  }

  getTaxonomyInfo(code: string) {
    return this.http.get(`${environment.API_URL}taxonomy/${code}`).pipe(
      map((result) => {
        return result;
      })
    );
  }

  GetGeneralSetting(): Observable<any> {
    return this.http.get(`${environment.API_URL}general-setting/provider`).pipe(
      map((info: any) => {
        return info;
      })
    );
  }

  /**
   * Get Correct Specialty Code
   */
  getSpecialtyCode(specialty, providerType) {
    if (!specialty) return 'N/A';
    const { primaryCode1, primaryCode2, primaryCode3 } = specialty;
    let primaryCode = '';
    switch (providerType) {
      case '001':
        primaryCode = MD_DO[primaryCode1];
        break;
      case '007':
        primaryCode = MD_DO[primaryCode1];
        break;
      case '004':
        primaryCode = MD_DPM_MERGED[primaryCode2];
        break;
      case '006':
        primaryCode = DC[primaryCode2];
        break;
      case '003':
        primaryCode = DDS_DMD[primaryCode2];
        break;
      case '002':
        primaryCode = DDS_DMD[primaryCode2];
        break;
      case '081':
        primaryCode = ALLIED_PROVIDER_TYPE[primaryCode3];
        break;
    }
    return primaryCode;
  }

  getSpecialtyPrimaryCode(specialty, providerType) {
    if (!specialty) return 'N/A';
    const { primaryCode1, primaryCode2, primaryCode3 } = specialty;
    let primaryCode = '';
    switch (providerType) {
      case '001':
        primaryCode = primaryCode1;
        break;
      case '007':
        primaryCode = primaryCode1;
        break;
      case '004':
        primaryCode = primaryCode2;
        break;
      case '006':
        primaryCode = primaryCode2;
        break;
      case '003':
        primaryCode = primaryCode2;
        break;
      case '002':
        primaryCode = primaryCode2;
        break;
      case '081':
        primaryCode = primaryCode3;
        break;
    }
    return primaryCode;
  }

  /**
   * Get Correct Specialty
   */
  getSpecialty(specialty, providerType) {
    return this.getSpecialtyCode(specialty, providerType);
  }

  /**
   * Get Correct Certifying Board Of a Specialty
   */
  getCertifyingBoard(specialty, providerType) {
    const { code1BoardCertPri, certifyingBoardAlliedHealth } = specialty;
    return !providerType
      ? ''
      : providerType === '081'
      ? ALL_BOARDS[certifyingBoardAlliedHealth]
      : ALL_BOARDS[code1BoardCertPri];
  }

  SaveApptDate(providerId: string, data: Array<DateItems>): Observable<any> {
    const _data = {
      userId: providerId,
      appointmentDatesInfo: data,
    };
    return this.http.post(
      `${environment.API_URL}provider-location/dates`,
      _data
    );
  }

  GetApptDate(providerId: string): Observable<Array<DateItemsModel>> {
    return this.http.get<Array<DateItemsModel>>(
      `${environment.API_URL}provider-location/dates/${providerId}`
    );
  }
  RemoveApptDate(apptDateId: string): Observable<any> {
    return this.http.delete(
      `${environment.API_URL}provider-location/dates/${apptDateId}`
    );
  }

  updateSpecialtyExpDate(
    providerId: string,
    _data: { primaryExpDate: string }
  ): Observable<any> {
    return this.http.put(
      `${environment.API_URL}organization-info/specialities/expire-date/${providerId}`,
      _data
    );
  }

  cfNextStep(router: Router, reverse: boolean = false) {
    const paths = router.url.split('/');
    const currentPath = paths[paths.length - 1];
    const indx = COMMON_FORMS_STEPS.findIndex((x) => x.path === currentPath);
    const nextIndex = !reverse ? indx + 1 : indx - 1;
    if (
      indx > -1 &&
      ((nextIndex < COMMON_FORMS_STEPS.length && !reverse) ||
        (nextIndex > -1 && reverse))
    ) {
      const url = `/provider/common-form/${COMMON_FORMS_STEPS[nextIndex].path}`;
      router.navigate([url]);
    }
  }

  commonFormSummaryNavigation(cform: string, router: Router) {
    const indx = COMMON_FORMS_STEPS.findIndex(
      (cf) => cf.summaryTitle && cf.summaryTitle === cform
    );
    if (indx > -1) {
      const url = `/provider/common-form/${COMMON_FORMS_STEPS[indx].path}`;
      router.navigate([url]);
    }
  }

  calculateTotalYears(info: { startDate: string; endDate: string }[]): number {
    let totalDays = 0;
    if (!info || !info.length) return totalDays;

    info = info.sort((a, b) => a.startDate.localeCompare(b.startDate));
    let currentEndDate = moment(info[0].startDate);

    for (let item of info) {
      const startDate = moment(item.startDate);
      const endDate = moment(item.endDate);

      if (startDate <= currentEndDate) {
        if (endDate > currentEndDate) {
          totalDays += endDate.diff(currentEndDate, 'days');
          currentEndDate = endDate;
        }
      } else {
        totalDays += endDate.diff(startDate, 'days');
        currentEndDate = endDate;
      }
    }

    return totalDays / 365.25;
  }
}
