import { EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { Certification } from "src/app/view-models/certification";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { BaseCommonFormComponent } from "./base.common.form.component";

type providerInfo = Certification | PersonalInformation;
export abstract class EditDocumentItem<T> extends BaseCommonFormComponent<providerInfo> implements OnInit {
  certificationForm: FormGroup;
  certificationInfo: providerInfo;
  arrayList = [];
  dateList = [];
  editModal: boolean = false;
  providerId;
  dateFields: string[];

  @Output() abstract onChangeForm;
  @Output() onSave = new EventEmitter();

  @Input()
  get certification() {
    let certification = (this.certificationForm as FormGroup).value;
    this.ConvertDate(certification);
    return certification;
  }

  set certification(val) {

    if (val) {
      this.certificationInfo = val
      this.PatchValue(val)
    }
  }

  constructor(
    protected builder: FormBuilder,
    protected service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<T>
  ) {
    super(service)
    if (dialogData) {
      const { certification, providerId } = dialogData;
      this.certification = certification;
      this.PatchValue(certification);
      this.editModal = true;
      this.providerId = providerId;
    }
  }

  ngOnInit() {
    this.CreateForm();
  }

  PatchValue(certification: providerInfo) {
    if (certification && this.certificationForm) {
      this.certificationInfo = certification;
      for (let item of Object.keys(certification)) {
        if (this.certificationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.certificationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(certification[item])
                );
            } else {
              this.certificationForm.get(item).patchValue(certification[item]);
            }
          }
        }
      }
    }
  }

  /**
   * Create an empty form for a new document item
   * @returns FormGroup
   */
  abstract CreateForm();

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let certification = this.certificationForm.value;
    this.ConvertDate(certification);

    const isValid = this.certificationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.certificationForm);
    this.onChangeForm.emit({ ...certification, isValid });
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveForm() {
    let certification = this.certificationForm.value;
    this.ConvertDate(certification);

    const isValid = this.certificationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.certificationForm);

    this.onSave.emit(certification);
  }

}