<form [formGroup]="agent" (ngSubmit)="AddNewAgent()">
    <div class="container custom-form-design">
        <div class="row">
            <div class="col">
                <h1>Add New Agent</h1>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-5 label">
                First Name
            </div>
            <div class="col-7 input-feild">
                <input class="form-control" type="text" formControlName="firstName" placeholder="Enter First Name">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-5 label">
                Last Name
            </div>
            <div class="col-7 input-feild">
                <input class="form-control" type="text" formControlName="lastName" placeholder="Enter Last Name">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-5 label">
                Email
            </div>
            <div class="col-7 input-feild">
                <input class="form-control" type="email" formControlName="email" placeholder="Enter Email">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-12 input-feild">
                <mat-checkbox class="example-margin" formControlName="isCheckTerms">By checking this box, I agree to the terms specified in the Agent Agreement.</mat-checkbox>
            </div>
        </div>
        <div class="row form-group buttons">
            <div class="col-12 input-feild">
                <button [disabled]="!agent.valid" type="submit" class="rectangle-button orange-button">Add Agent</button>
            </div>
        </div>
    </div>
</form>