<div class="row">
    <div class="col">
        <h4 class="mt-10 mb-20">Work History</h4>
    </div>
</div>
<form [formGroup]="workHistoryForm">
        <div class="row custom-form-design">
            <div class="col">
                <div class="row form-group">
                    <div class="col-5 label">Name and Address of Practice/Employer<span class="text-danger">*</span></div>
                    <div class="col-7 input-feild">
                        <div class="row form-group">
                            <div class="col">
                                <input formControlName="namePract" class="form-control" ngx-google-places-autocomplete
                                    [options]='options' #placesRef="ngx-places" (onAddressChange)="AddressChange($event)" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col"><input type="text" class="form-control" placeholder="Street Address 1"
                                    formControlName="addressStreetPract"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col"><input type="text" class="form-control" placeholder="Street Address 2"
                                    formControlName="addressStreet2Pract"></div>
                        </div>
                        <div class="row form-group">
                            <div class="col"><input type="text" class="form-control" placeholder="City"
                                    formControlName="addressCityPract"></div>
                            <div class="col"><input type="text" class="form-control" placeholder="State/Province"
                                    formControlName="addressStatePract"></div>
                            <div class="col"><input type="text" class="form-control" placeholder="Zip"
                                    formControlName="addressZipPract"></div>
                        </div>
                    </div>
                </div>
            
                <div class="row form-group">
                    <div class="col-5 label">Position/Title <span class="text-danger">*</span></div>
                    <div class="col-7 input-feild"><input type="text" class="form-control" placeholder="Enter Position/Title"
                            formControlName="position"></div>
                </div>
            
                <div class="row form-group">
                    <div class="col-5 label">Start Date <span class="text-danger">*</span></div>
                    <div class="col-7 input-feild date-picker">
                        <input class="form-control" placeholder="mm/dd/yyyy" name="startingPract" placement="top" ngbDatepicker
                            type="date" (keyup)="onDateChange($event)" formControlName="startingPract"
                            #startingPract="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="calendar" (click)="startingPract.toggle()" type="button">
                                <i class="icon-calender"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5 label">I am currently working here <span class="text-danger">*</span></div>
                    <div class="col-7 input-feild">No <mat-slide-toggle formControlName="isCurrentlyHere"
                            (change)="updateEndingPractValiditors()">Yes</mat-slide-toggle></div>
                </div>
                <div class="row form-group"
                    *ngIf="!workHistoryForm.controls.isCurrentlyHere.value">
                    <div class="col-5 label">End Date <span class="text-danger">*</span></div>
                    <div class="col-7 input-feild date-picker">
                        <input class="form-control" placeholder="mm/dd/yyyy" name="endingPract" placement="top" ngbDatepicker
                            type="date" (keyup)="onDateChange($event)" formControlName="endingPract" #endingPract="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="calendar" (click)="endingPract.toggle()" type="button">
                                <i class="icon-calender"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row form-group"
                    *ngIf="!workHistoryForm.controls.isCurrentlyHere.value">
                    <div class="col-5 label">Reason for leaving </div>
                    <div class="col-7 input-feild">
                        <textarea name="leavingsReason" rows="5" formControlName="leavingsReason"></textarea>
                    </div>
                </div>
                <div class="row form-group" *ngIf="workHistoryForm.controls.hasGap?.value">
                    <div class="col-5 label">Reason for work gap <span class="text-danger">*</span></div>
                    <div class="col-7 input-feild">
                        <textarea name="gapExplanation" rows="5" formControlName="gapExplanation"></textarea>
                    </div>
                </div>
            
            </div>
        </div>
        <div class="row button-section">
            <div class="col-5 form-group">
                <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                    Cancel</button>
            </div>
            <div class="col-7 form-group">
                <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i class="icon-check"></i>
                    Save</button>
            </div>
        </div>
</form>