import { Component, EventEmitter, Inject, Optional, Output } from "@angular/core";
import {
  FormBuilder,
  Validators
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { EditDocumentItem } from "src/app/shared/utils/editDocumentItem";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "eddy-provider-ALSO-documents",
  templateUrl: "ALSO.component.html",
  styleUrls: ["ALSO.component.scss"],
})
export class ALSODocumentsComponent extends EditDocumentItem<ALSODocumentsComponent> {
  isAdvOB = true;
  arrayList = [];
  dateList = [
    "expireAdvOB"
  ];

  @Output("onChangeALSO")
  onChangeForm = new EventEmitter();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() dialogRef: MatDialogRef<ALSODocumentsComponent>
  ) {
    super(builder, service, dialogData, dialogRef)
  }

  CreateForm() {
    this.certificationForm = this.builder.group({
      isAdvOB: [true, Validators.required],
      numberALSO: ['', Validators.required],
      expireAdvOB: [null, Validators.required],
    });

    if (this.certificationInfo) {
      this.PatchValue(this.certificationInfo);
    }
  }

}
