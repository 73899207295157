<form [formGroup]="docForm">
    <!-- Start Medicaid FormArray -->
    <div class="row custom-form-design form-group">
        <div class="col section form-modal">
            <div class="row form-group">
                <div class="col-5 label">Individual Medicaid Number <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild"><input type="text" class="form-control"
                        placeholder="Enter Individual Medicaid number" formControlName="medicadeNo">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Medicaid State <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild">
                    <ng-select class="auto-select" bindLabel="value" bindValue="value" [items]="stateOptions"
                        formControlName="medicadeState" placeholder="Select State"
                        filterPlaceholder="Begin typing to Select State" name="medicadeState">
                    </ng-select>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Issue date of Medicaid License <span class="text-danger">*</span>
                </div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="issuanceMedicaid" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                        formControlName="issuanceMedicaid" #issuanceMedicaid="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="issuanceMedicaid.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-5 label">Expiration date of Medicaid License <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="expireIndMedicaid" placement="bottom"
                        ngbDatepicker type="date" (ngModelChange)="onDateChange($event)"
                        formControlName="expireIndMedicaid" #expireIndMedicaid="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="expireIndMedicaid.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4 group-button">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveInfo()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End Medicaid FormArray -->
</form>