import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Store } from "@ngrx/store";
import { State } from "src/app/state/app.state";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-save-license",
  templateUrl: "save.license.component.html",
  styleUrls: ["save.license.component.scss"],
})
export class SaveProviderLicenseComponent extends SaveDocumentItem<SaveProviderLicenseComponent> {
  dateFields = ['issuanceStLicense', 'expireStLicense'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  licenseTypeOptions: Array<IOption> = this.selectOptionService.getProviders();
  providerType: string;
  specialty;


  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderLicenseComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
    private store: Store<State>,
  ) {
    super(builder, service, dialogRef, _info);

    const { providerType, specialities } = _info;

    this.providerType = providerType;
    if (this.providerType && this.providerType === "081")
      this.licenseTypeOptions = this.selectOptionService.sortOptions(this.selectOptionService.getAlliedProviders());

    this.specialty = specialities && specialities.specialityData &&
      specialities.specialityData.length ?
      specialities.specialityData[0] : null;;
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      status: [info ? info.status : 'Individual', Validators.required],
      stateStLicense: [info ? info.stateStLicense : null, Validators.required],
      numStLicense: [info ? info.numStLicense : "", Validators.required],
      statusCodeStLicense: [info ? info.statusCodeStLicense : null],
      typeStLicense: [this.setTypeStLicense(info)],
      isParticipating: [info ? info.isParticipating : false],
      issuanceStLicense: [info ? this.service.ConvertStringToDate(info.issuanceStLicense) : null, Validators.required],
      expireStLicense: [info ? this.service.ConvertStringToDate(info.expireStLicense) : null, Validators.required],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    return this.docForm;
  }

  isPrimaryChange(event: any) {
    this.docForm.get("isPrimary").setValue(event);
    event && this.docForm.get("typeStLicense").setValue(this.setTypeStLicense(this.info));
  }

  setTypeStLicense(info: any) {
    return info && info.isPrimary && this.providerType && this.providerType === "081" ?
      this.getSpecialty() :
      info && info.typeStLicense ?
        info.typeStLicense : null
  }

  getSpecialty() {
    return this.service.getSpecialtyCode(this.specialty, this.providerType)
  }
}
