import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-clia",
  templateUrl: "save.clia.component.html",
  styleUrls: ["save.clia.component.scss"],
})
export class SaveProviderCLIAComponent extends SaveDocumentItem<SaveProviderCLIAComponent> {
  dateFields = ['issuanceCLIA'];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderCLIAComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      issuanceCLIA: [info ? this.service.ConvertStringToDate(info.issuanceCLIA) : ""],
      numberCLIA: [info ? info.numberCLIA : "", Validators.required],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
