import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-save-medicaid",
  templateUrl: "save.medicaid.component.html",
  styleUrls: ["save.medicaid.component.scss"],
})
export class SaveProviderMedicaidComponent extends SaveDocumentItem<SaveProviderMedicaidComponent> {
  dateFields = ['issuanceMedicaid', 'expireIndMedicaid'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderMedicaidComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      medicadeState: [info ? info.medicadeState : "", Validators.required],
      medicadeNo: [info ? info.medicadeNo : "", Validators.required],
      issuanceMedicaid: [info ? this.service.ConvertStringToDate(info.issuanceMedicaid) : "", Validators.required],
      expireIndMedicaid: [info ? this.service.ConvertStringToDate(info.expireIndMedicaid) : null, Validators.required],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
