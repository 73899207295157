import { EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { BaseCommonFormComponent } from "./base.common.form.component";

export abstract class EditPersonalInfoItem<T> extends BaseCommonFormComponent<PersonalInformation> implements OnInit  {
  personalInformationForm: FormGroup;
  personalInformationInfo: PersonalInformation;
  arrayList = [];
  dateList = [];
  editModal: boolean = false;
  providerId;
  dateFields: string[];

  @Output() abstract onChangeForm;
  @Output() onSave = new EventEmitter();

  @Input()
  get personalInformation() {
    let personalInformation = (this.personalInformationForm as FormGroup).value;
    this.ConvertDate(personalInformation);
    return personalInformation;
  }

  set personalInformation(val) {

    if (val) {
      this.personalInformationInfo = val
      this.PatchValue(val)
    }
  }

  constructor(
    protected builder: FormBuilder,
    protected service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<T>
  ) {
    super(service);
    if (dialogData) {
      const { personalInformation, providerId } = dialogData;
      this.personalInformation = personalInformation;
      this.PatchValue(personalInformation);
      this.editModal = true;
      this.providerId = providerId;
    }
  }

  ngOnInit() {
    this.CreateForm();
  }

  PatchValue(personalInformation: PersonalInformation) {
    if (personalInformation && this.personalInformationForm) {
      this.personalInformationInfo = personalInformation;
      for (let item of Object.keys(personalInformation)) {
        if (this.personalInformationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.personalInformationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(personalInformation[item])
                );
            } else {
              this.personalInformationForm.get(item).patchValue(personalInformation[item]);
            }
          }
        }
      }
    }
  }

  /**
   * Create an empty form for a new document item
   * @returns FormGroup
   */
  abstract CreateForm();

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let personalInformation = this.personalInformationForm.value;
    this.ConvertDate(personalInformation);

    const isValid = this.personalInformationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.personalInformationForm);
    this.onChangeForm.emit({ ...personalInformation, isValid });
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveForm() {
    let personalInformation = this.personalInformationForm.value;
    this.ConvertDate(personalInformation);

    const isValid = this.personalInformationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.personalInformationForm);

    this.onSave.emit(personalInformation);
  }

}