import { Component, EventEmitter, Inject, Optional, Output } from "@angular/core";
import {
  FormBuilder,
  Validators
} from "@angular/forms";
import { SelectOptionService } from "src/app/services/select-option.service";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { CommonFormService } from "src/app/services/common-form.service";
import { IOption } from "src/app/view-models/ng-select-option";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { EditPersonalInfoItem } from "src/app/shared/utils/editPersonalInfoItem";

@Component({
  selector: "eddy-provider-PersonalInformation-documents",
  templateUrl: "gov-issued.component.html",
  styleUrls: ["gov-issued.component.scss"],
})
export class PersonalInformationDocumentsComponent extends EditPersonalInfoItem<PersonalInformationDocumentsComponent> {
  personalInformationInfo: PersonalInformation;
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  arrayList = [];
  dateList = [
    "driverLicenseExpireDate"
  ];
  govIssuedTypeSelected: string;


  @Output("onChangePersonalInformation")
  onChangeForm = new EventEmitter();

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() dialogRef: MatDialogRef<PersonalInformationDocumentsComponent>,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogData, dialogRef)
  }

  CreateForm() {
    this.personalInformationForm = this.builder.group({
      driverLicenseExpireDate: [null, Validators.required],
      driverLicenseNo: [''],
      govIssuedType: ['', Validators.required],
      stateDriverLicense: [null],
    });

    if (this.personalInformationInfo) {
      this.PatchValue(this.personalInformationInfo);
    }
  }


  PatchValue(personalInformation: PersonalInformation) {
    if (personalInformation && this.personalInformationForm) {
      this.personalInformationInfo = personalInformation;
      for (let item of Object.keys(personalInformation)) {
        if (this.personalInformationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.personalInformationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(personalInformation[item])
                );
            } else {
              this.personalInformationForm.get(item).patchValue(personalInformation[item]);
            }
          }
        }
        if (item === "govIssuedType") {
          this.govIssuedTypeSelected = personalInformation[item];
        }
      }
    }
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    this.personalInformationForm.get('govIssuedType').patchValue(this.govIssuedTypeSelected)
    let personalInformation = this.personalInformationForm.value;
    this.ConvertDate(personalInformation);

    const isValid = this.personalInformationForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.personalInformationForm);
    this.onChangeForm.emit({ ...personalInformation, isValid });
  }

}
