import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatStepperModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
} from '@angular/material';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
// Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbLayoutModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
  NbInputModule,
  NbRadioModule,
} from '@nebular/theme';
import {
  NgbDateParserFormatter,
  NgbModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DxDataGridModule } from 'devextreme-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TagInputModule } from 'ngx-chips';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AddSplitPipe } from '../pipes/add-split-pipe';
import { AddressPipe } from '../pipes/address.pipe';
import { AffiliationStatusPipe } from '../pipes/affiliation-status.pipe';
import { ALLSpecialtiesPipe } from '../pipes/all-specialties-pipe';
import { AlliedProviderPipe } from '../pipes/allied-provider-pipe';
import { CountryPipe } from '../pipes/countries-pipe';
import { CurrentPrivilegePipe } from '../pipes/current-privilege-pipe';
import { CustomDatePipe } from '../pipes/custom-date.pipe';
import { DCPipe } from '../pipes/DC-pipe';
import { DDSDMDPipe } from '../pipes/dds-dmd-pipe';
import { DPMPipe } from '../pipes/dpm-pipe';
import { FilterListPipe } from '../pipes/filter.pipe';
import { LanguagePipe } from '../pipes/language-pipe';
import { MdDoPipe } from '../pipes/md-do-pipe';
import { MDDPDCPipe } from '../pipes/MD_DP_DC-pipe';
import { MilitaryServiceBranchesPipe } from '../pipes/military-branches-pipe';
import { PhoneConvertPipe } from '../pipes/phone-convert.pipe';
import { PracticeTypePipe } from '../pipes/practice-type.pipe';
import { PrimarySpecialtyPipe } from '../pipes/primary-specialty';
import { ProviderTypePipe } from '../pipes/provider-type-pipe';
import { SsnPipe } from '../pipes/ssn.pipe';
import { StateCodePipe } from '../pipes/state-code.pipe';
import { StatusCodeLicensePipe } from '../pipes/status-code-license-pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { NgbdAlertCustom } from './components/alert/alert.component';
import { ArchiveFileModalComponent } from './components/archive-file-modal/archive-file-modal.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ProviderCDSComponent } from './components/common-forms/certification/cds/cds.component';
import { ProviderCLIAComponent } from './components/common-forms/certification/clia/clia.component';
import { ProviderDEAComponent } from './components/common-forms/certification/dea/dea.component';
import { ProviderFluComponent } from './components/common-forms/certification/Flu/Flu.component';
import { ProviderLicenseComponent } from './components/common-forms/certification/license/license.component';
import { ProviderInsuranceComponent } from './components/common-forms/certification/Insurance/Insurance.component';
import { ProviderMedicaidComponent } from './components/common-forms/certification/medicaid/medicaid.component';
import { ProviderMedicareComponent } from './components/common-forms/certification/medicare/medicare.component';
import { ProviderPassportComponent } from './components/common-forms/certification/passport/passport.component';
import { ProviderTBComponent } from './components/common-forms/certification/TB/TB.component';
import { ProviderVaccineComponent } from './components/common-forms/certification/vaccine/vaccine.component';
import { ProviderVisaComponent } from './components/common-forms/certification/visa/visa.component';
import { NonEnglishLanguagesComponent } from './components/common-forms/personal-info/non-english-languages/non-english-languages.component';
import { CoveragePlanComponent } from './components/common-forms/primary-practice/coverage-plan/coverage-plan.component';
import { CoveringPractitionerComponent } from './components/common-forms/primary-practice/covering-practitioner/covering-practitioner.component';
import { ConfirmCreatePSVArchiveComponent } from './components/confirm-create-psv-archive/confirm-create-psv-archive.component';
import { ConfirmDeleteModalComponent } from './components/confirm-delete-modal/confirm-delete-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ConfirmNewPSVArchiveFileComponent } from './components/confirm-new-psv-archive-file/confirm-new-psv-archive-file.component';
import { CreateTaskComponent } from './components/create-task/create-task.component';
import { NgbDateCustomParserFormatter } from './components/date-format/date-format';
import { EnrollProviderComponent } from './components/enroll-provider/enroll-provider.component';
import { EventsSideBarComponent } from './components/events-sidebar/events-sidebar.component';
import { IntlTelInputModule } from './components/intl-tel-input/src/public_api';
import { NewFacilityComponent } from './components/new-facility/new-facility.component';
import { NewHospitalComponent } from './components/new-hospital/new-hospital.component';
import { NoItemComponent } from './components/no-item/no-item.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { ABLSDocumentsComponent } from './components/provider-documents/ABLS/ABLS.component';
import { ACLSDocumentsComponent } from './components/provider-documents/ACLS/ACLS.component';
import { ALSODocumentsComponent } from './components/provider-documents/ALSO/ALSO.component';
import { ATLSDocumentsComponent } from './components/provider-documents/ATLS/ATLS.component';
import { BLSDocumentsComponent } from './components/provider-documents/BLS/BLS.component';
import { CDSDocumentsComponent } from './components/provider-documents/CDS/CDS.component';
import { CLIADocumentsComponent } from './components/provider-documents/CLIA/CLIA.component';
import { DEADocumentsComponent } from './components/provider-documents/DEA/DEA.component';
import { ECFMGDocumentsComponent } from './components/provider-documents/ECFMG/ECFMG.component';
import { FLUDocumentsComponent } from './components/provider-documents/FLU/FLU.component';
import { PersonalInformationDocumentsComponent } from './components/provider-documents/gov-issued/gov-issued.component';
import { ICCDocumentsComponent } from './components/provider-documents/infection-control/infection-control.component';
import { InsuranceDocumentsComponent } from './components/provider-documents/Insurance/Insurance.component';
import { LicenseDocumentsComponent } from './components/provider-documents/License/License.component';
import { MedicaidDocumentsComponent } from './components/provider-documents/Medicaid/Medicaid.component';
import { MedicareDocumentsComponent } from './components/provider-documents/Medicare/Medicare.component';
import { NALSDocumentsComponent } from './components/provider-documents/NALS/NALS.component';
import { NRPDocumentsComponent } from './components/provider-documents/NRP/NRP.component';
import { OPIOIDDocumentsComponent } from './components/provider-documents/OPIOID/OPIOID.component';
import { PALSDocumentsComponent } from './components/provider-documents/PALS/PALS.component';
import { SpecialtyDocumentsComponent } from './components/provider-documents/Specialty/Specialty.component';
import { TBDocumentsComponent } from './components/provider-documents/TB/TB.component';
import { vaccineDocumentsComponent } from './components/provider-documents/Vaccine/Vaccine.component';
import { VisaDocumentsComponent } from './components/provider-documents/Visa/Visa.component';
import { ShowFileComponent } from './components/show-file/show-file.component';
import { ShowMessageComponent } from './components/show-message/show-message.component';
import { ShowPDFComponent } from './components/show-pdf/show-pdf.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { TaxonomySearchBoxComponent } from './components/taxonomy-search-box/taxonomy-search-box.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { UploadProviderDocumentComponent } from './components/upload-provider-document/upload-provider-document.component';
import { RemoveNumbersPipe } from '../pipes/remove-numbers.pipe';
import { DrugSchedulesPipe } from '../pipes/drug-schedules-pipe';
import { AddAgentComponent } from './components/add-agent/add-agent.component';
import { NotesComponent } from './components/notes/notes.component';
import { CAQHDocumentsComponent } from './components/provider-documents/CAQH/CAQH.component';
import { CurrentPrivilegeComponent } from './components/common-forms/summary/current-privilege/current-privilege.component';
import { DisclosureQuestionsComponent } from './components/common-forms/summary/disclosure-questions/disclosure-questions.component';
import { EducationInfoComponent } from './components/common-forms/summary/education-info/education-info.component';
import { ExpirablesComponent } from './components/common-forms/summary/expirables/expirables.component';
import { MilitaryServicesComponent } from './components/common-forms/summary/military-services/military-services.component';
import { PersonalInfoComponent } from './components/common-forms/summary/personal-info/personal-info.component';
import { PrimaryPracticeComponent } from './components/common-forms/summary/primary-practice/primary-practice.component';
import { PrivilegesComponent } from './components/common-forms/summary/privileges/privileges.component';
import { ProfessionalLiabilityComponent } from './components/common-forms/summary/professional-liability/professional-liability.component';
import { SpecialtiesComponent } from './components/common-forms/summary/specialties/specialties.component';
import { TrainingComponent } from './components/common-forms/summary/training/training.component';
import { WorkHistoryComponent } from './components/common-forms/summary/work-history/work-history.component';
import { CertificationsComponent } from './components/common-forms/summary/certifications/certifications.component';
import { ProviderReferencesDialogComponent } from './components/common-forms/summary/provider-references-dialog/provider-references-dialog.component';
import { ProviderSupportingDocumentsComponent } from './components/provider-documents/provider-supporting-documents/provider-supporting-documents.component';
import { AddFileTemplateComponent } from './components/add-new-file-template/add-file-template.component';
import { HasPermissionDirective } from './directives/hasPermission.directive';
import { TrimDatePipe } from '../pipes/trim-date';
import { ProvidersAddressComponent } from './components/common-forms/personal-info/providers-address/providers-address.component';
import { HealthAssessmentDocumentsComponent } from './components/provider-documents/health-assessment/health-assessment.component';
import { ProviderHealthAssessmentComponent } from './components/common-forms/certification/health-assessment/health-assessment.component';
import { HepBDocumentsComponent } from './components/provider-documents/Hep-B/Hep-B.component';
import { ProviderHepBComponent } from './components/common-forms/certification/Hep-B/Hep-B.component';
import { ProviderMMRComponent } from './components/common-forms/certification/mmr/mmr.component';
import { MMRDocumentsComponent } from './components/provider-documents/MMR/MMR.component';
import { SaveProviderVaccineComponent } from './components/common-forms/certification/vaccine/save/save.vaccine.component';
import { SaveProviderFluComponent } from './components/common-forms/certification/Flu/save/save.flu.component';
import { SaveProviderHealthAssessmentComponent } from './components/common-forms/certification/health-assessment/save/save.health-assessment.component';
import { SaveProviderHepBComponent } from './components/common-forms/certification/Hep-B/save/save.hep-b.component';
import { SaveProviderMMRComponent } from './components/common-forms/certification/mmr/save/save.mmr.component';
import { SaveProviderTBComponent } from './components/common-forms/certification/TB/save/save.tb.component';
import { SaveProviderCLIAComponent } from './components/common-forms/certification/clia/save/save.clia.component';
import { SaveProviderDEAComponent } from './components/common-forms/certification/dea/save/save.dea.component';
import { SaveProviderMedicaidComponent } from './components/common-forms/certification/medicaid/save/save.medicaid.component';
import { SaveProviderMedicareComponent } from './components/common-forms/certification/medicare/save/save.medicare.component';
import { SaveProviderLicenseComponent } from './components/common-forms/certification/license/save/save.license.component';
import { SaveProviderCDSComponent } from './components/common-forms/certification/cds/save/save.cds.component';
import { SaveProviderVisaComponent } from './components/common-forms/certification/visa/save/save.visa.component';
import { SaveProviderInsuranceComponent } from './components/common-forms/certification/Insurance/save/save.insurance.component';
import { SaveTrainingComponent } from './components/common-forms/training/save/training.component';
import { SaveSpecialtyComponent } from './components/common-forms/specialties/save/specialties.component';
import { SavePrimaryPracticeComponent } from './components/common-forms/primary-practice/save/primary-practice.component';
import { SaveWorkHistoryComponent } from './components/common-forms/work-history/save/work-history.component';

@NgModule({
  imports: [
    Ng2SearchPipeModule,
    DxDataGridModule,
    NbLayoutModule,
    NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
    NbButtonModule,
    NbTabsetModule,
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatStepperModule,
    MatInputModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NbMenuModule,
    NbCardModule,
    NgbModule,
    NbUserModule,
    NgxMaskModule.forRoot(),
    NbTreeGridModule,
    ToastrModule.forRoot(),
    SignaturePadModule,
    NbSpinnerModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    GooglePlaceModule,
    NbProgressBarModule,
    TagInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    Ng2TelInputModule,
    NbAccordionModule,
    NbSelectModule,
    MatSelectModule,
    PdfViewerModule,
    IntlTelInputModule,
    NgbPaginationModule,
    MatSortModule,
    NbInputModule,
    MatTabsModule,
    NbRadioModule,
  ],
  declarations: [
    // Components
    BreadcrumbsComponent,
    NoItemComponent,
    SignOutComponent,
    ShowFileComponent,
    TaxonomySearchBoxComponent,
    CoveragePlanComponent,
    CoveringPractitionerComponent,
    ProviderPassportComponent,
    ProviderVisaComponent,
    SaveProviderVisaComponent,
    ProviderFluComponent,
    SaveProviderFluComponent,
    ProviderTBComponent,
    SaveProviderTBComponent,
    ProviderVaccineComponent,
    SaveProviderVaccineComponent,
    ProviderCLIAComponent,
    SaveProviderCLIAComponent,
    ProviderDEAComponent,
    SaveProviderDEAComponent,
    ProviderMedicareComponent,
    SaveProviderMedicareComponent,
    ProviderMedicaidComponent,
    SaveProviderMedicaidComponent,
    ProviderLicenseComponent,
    SaveProviderLicenseComponent,
    ProviderCDSComponent,
    SaveProviderCDSComponent,
    ProviderInsuranceComponent,
    SaveProviderInsuranceComponent,
    ProviderHealthAssessmentComponent,
    SaveProviderHealthAssessmentComponent,
    ProviderHepBComponent,
    SaveProviderHepBComponent,
    ProviderMMRComponent,
    SaveProviderMMRComponent,
    ABLSDocumentsComponent,
    ACLSDocumentsComponent,
    ALSODocumentsComponent,
    ATLSDocumentsComponent,
    CLIADocumentsComponent,
    BLSDocumentsComponent,
    NALSDocumentsComponent,
    NRPDocumentsComponent,
    PALSDocumentsComponent,
    ICCDocumentsComponent,
    FLUDocumentsComponent,
    TBDocumentsComponent,
    vaccineDocumentsComponent,
    VisaDocumentsComponent,
    DEADocumentsComponent,
    MedicareDocumentsComponent,
    MedicaidDocumentsComponent,
    LicenseDocumentsComponent,
    CDSDocumentsComponent,
    ECFMGDocumentsComponent,
    OPIOIDDocumentsComponent,
    InsuranceDocumentsComponent,
    SpecialtyDocumentsComponent,
    PersonalInformationDocumentsComponent,
    CAQHDocumentsComponent,
    HealthAssessmentDocumentsComponent,
    HepBDocumentsComponent,
    MMRDocumentsComponent,
    PhoneInputComponent,
    NonEnglishLanguagesComponent,
    ProvidersAddressComponent,
    UploadFileComponent,
    UploadProviderDocumentComponent,
    ShowMessageComponent,
    NewHospitalComponent,
    CalendarComponent,
    ConfirmDeleteModalComponent,
    ConfirmCreatePSVArchiveComponent,
    ConfirmNewPSVArchiveFileComponent,
    ArchiveFileModalComponent,
    EnrollProviderComponent,
    ShowPDFComponent,
    ConfirmModalComponent,
    NewFacilityComponent,
    NgbdAlertCustom,
    // Pipes
    PhoneConvertPipe,
    AffiliationStatusPipe,
    ProviderTypePipe,
    EventsSideBarComponent,
    TruncatePipe,
    AccordionComponent,
    MdDoPipe,
    MDDPDCPipe,
    DCPipe,
    TrimDatePipe,
    MilitaryServiceBranchesPipe,
    ALLSpecialtiesPipe,
    AddSplitPipe,
    AlliedProviderPipe,
    DDSDMDPipe,
    DPMPipe,
    StatusCodeLicensePipe,
    LanguagePipe,
    FilterListPipe,
    BackButtonComponent,
    CreateTaskComponent,
    StateCodePipe,
    CustomDatePipe,
    CountryPipe,
    SsnPipe,
    CurrentPrivilegePipe,
    PracticeTypePipe,
    AddressPipe,
    PrimarySpecialtyPipe,
    RemoveNumbersPipe,
    DrugSchedulesPipe,
    AddAgentComponent,
    NotesComponent,
    AddFileTemplateComponent,
    // CF Summary
    CertificationsComponent,
    CurrentPrivilegeComponent,
    DisclosureQuestionsComponent,
    EducationInfoComponent,
    ExpirablesComponent,
    MilitaryServicesComponent,
    PersonalInfoComponent,
    PrimaryPracticeComponent,
    SavePrimaryPracticeComponent,
    PrivilegesComponent,
    ProfessionalLiabilityComponent,
    SpecialtiesComponent,
    SaveSpecialtyComponent,
    TrainingComponent,
    SaveTrainingComponent,
    WorkHistoryComponent,
    SaveWorkHistoryComponent,
    ProviderReferencesDialogComponent,
    ProviderSupportingDocumentsComponent,
    // directive
    HasPermissionDirective,
  ],
  exports: [
    Ng2SearchPipeModule,
    DxDataGridModule,
    NbLayoutModule,
    NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
    NbButtonModule,
    NbTabsetModule,
    NbTreeGridModule,
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatInputModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NbMenuModule,
    NbCardModule,
    NgbModule,
    NgxMaskModule,
    NbUserModule,
    SignaturePadModule,
    NbSpinnerModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    GooglePlaceModule,
    TagInputModule,
    MatSlideToggleModule,
    NbProgressBarModule,
    NbCheckboxModule,
    NbTooltipModule,
    Ng2TelInputModule,
    NbAccordionModule,
    MatSelectModule,
    NbSelectModule,
    NgbPaginationModule,
    NbInputModule,
    MatTabsModule,
    //Components
    BreadcrumbsComponent,
    ToastrModule,
    NoItemComponent,
    SignOutComponent,
    ShowFileComponent,
    EventsSideBarComponent,
    AccordionComponent,
    PhoneInputComponent,
    UploadFileComponent,
    UploadProviderDocumentComponent,
    ShowMessageComponent,
    BackButtonComponent,
    NewHospitalComponent,
    CalendarComponent,
    ShowPDFComponent,
    ConfirmModalComponent,
    NewFacilityComponent,
    MatSortModule,
    NgbdAlertCustom,
    //Pipes
    AffiliationStatusPipe,
    TruncatePipe,
    StateCodePipe,
    ProviderTypePipe,
    MdDoPipe,
    MDDPDCPipe,
    DCPipe,
    TrimDatePipe,
    MilitaryServiceBranchesPipe,
    ALLSpecialtiesPipe,
    AddSplitPipe,
    AlliedProviderPipe,
    DDSDMDPipe,
    DPMPipe,
    StatusCodeLicensePipe,
    LanguagePipe,
    FilterListPipe,
    PdfViewerModule,
    IntlTelInputModule,
    CustomDatePipe,
    PhoneConvertPipe,
    CountryPipe,
    SsnPipe,
    CurrentPrivilegePipe,
    PracticeTypePipe,
    AddressPipe,
    PrimarySpecialtyPipe,
    NbRadioModule,
    TaxonomySearchBoxComponent,
    CoveragePlanComponent,
    CoveringPractitionerComponent,
    ProviderPassportComponent,
    ProviderVisaComponent,
    SaveProviderVisaComponent,
    ProviderFluComponent,
    SaveProviderFluComponent,
    ProviderTBComponent,
    SaveProviderTBComponent,
    ProviderVaccineComponent,
    SaveProviderVaccineComponent,
    ProviderCLIAComponent,
    SaveProviderCLIAComponent,
    ProviderDEAComponent,
    SaveProviderDEAComponent,
    ProviderMedicareComponent,
    SaveProviderMedicareComponent,
    ProviderMedicaidComponent,
    SaveProviderMedicaidComponent,
    ProviderLicenseComponent,
    SaveProviderLicenseComponent,
    ProviderCDSComponent,
    SaveProviderCDSComponent,
    ProviderInsuranceComponent,
    SaveProviderInsuranceComponent,
    ProviderHealthAssessmentComponent,
    SaveProviderHealthAssessmentComponent,
    ProviderHepBComponent,
    SaveProviderHepBComponent,
    ProviderMMRComponent,
    SaveProviderMMRComponent,
    ABLSDocumentsComponent,
    ACLSDocumentsComponent,
    ALSODocumentsComponent,
    ATLSDocumentsComponent,
    CLIADocumentsComponent,
    BLSDocumentsComponent,
    NALSDocumentsComponent,
    NRPDocumentsComponent,
    PALSDocumentsComponent,
    ICCDocumentsComponent,
    FLUDocumentsComponent,
    TBDocumentsComponent,
    vaccineDocumentsComponent,
    VisaDocumentsComponent,
    DEADocumentsComponent,
    MedicareDocumentsComponent,
    MedicaidDocumentsComponent,
    LicenseDocumentsComponent,
    CDSDocumentsComponent,
    ECFMGDocumentsComponent,
    OPIOIDDocumentsComponent,
    InsuranceDocumentsComponent,
    SpecialtyDocumentsComponent,
    PersonalInformationDocumentsComponent,
    CAQHDocumentsComponent,
    HealthAssessmentDocumentsComponent,
    HepBDocumentsComponent,
    MMRDocumentsComponent,
    NonEnglishLanguagesComponent,
    ProvidersAddressComponent,
    RemoveNumbersPipe,
    DrugSchedulesPipe,
    AddAgentComponent,
    NotesComponent,
    AddFileTemplateComponent,
    CertificationsComponent,
    CurrentPrivilegeComponent,
    DisclosureQuestionsComponent,
    EducationInfoComponent,
    ExpirablesComponent,
    MilitaryServicesComponent,
    PersonalInfoComponent,
    PrimaryPracticeComponent,
    SavePrimaryPracticeComponent,
    PrivilegesComponent,
    ProfessionalLiabilityComponent,
    SpecialtiesComponent,
    SaveSpecialtyComponent,
    TrainingComponent,
    SaveTrainingComponent,
    WorkHistoryComponent,
    SaveWorkHistoryComponent,
    ProviderReferencesDialogComponent,
    ProviderSupportingDocumentsComponent,
    // directive
    HasPermissionDirective,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  entryComponents: [
    ShowMessageComponent,
    NewHospitalComponent,
    CreateTaskComponent,
    NewFacilityComponent,
    AddAgentComponent,
    ShowPDFComponent,
    ECFMGDocumentsComponent,
    SaveProviderVaccineComponent,
    SaveProviderFluComponent,
    SaveProviderHealthAssessmentComponent,
    SaveProviderHepBComponent,
    SaveProviderMMRComponent,
    SaveProviderTBComponent,
    ABLSDocumentsComponent,
    ACLSDocumentsComponent,
    ALSODocumentsComponent,
    ATLSDocumentsComponent,
    BLSDocumentsComponent,
    NALSDocumentsComponent,
    NRPDocumentsComponent,
    PALSDocumentsComponent,
    SaveProviderCLIAComponent,
    SaveProviderDEAComponent,
    SaveProviderMedicaidComponent,
    SaveProviderMedicareComponent,
    SaveProviderLicenseComponent,
    SaveProviderCDSComponent,
    SaveProviderVisaComponent,
    PersonalInformationDocumentsComponent,
    CAQHDocumentsComponent,
    SaveProviderInsuranceComponent,
    SaveTrainingComponent,
    SaveSpecialtyComponent,
    SavePrimaryPracticeComponent,
    SaveWorkHistoryComponent,
  ],
})
export class SharedModule {}
