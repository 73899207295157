import { EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { Validators } from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

export abstract class BaseCommonFormComponent<T> {
  arrayList = [];
  dateList = [];
  editModal: boolean = false;
  providerId;
  dateFields: string[];

  @Output() abstract onChangeForm;
  @Output() onSave = new EventEmitter();


  constructor(
    protected service: CommonFormService,
  ) {
  }

  /**
   * Create an empty form for a new document item
   * @returns FormGroup
   */
  abstract CreateForm();

  requiredIfValidator(predicate) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  ConvertDate(info: T) {
    for (let item of Object.keys(info)) {
      if (this.arrayList.indexOf(item) >= 0) {
        // Check Date Time in Array
        for (let arrayItem of info[item]) {
          for (let key of Object.keys(arrayItem)) {
            if (this.dateList.indexOf(key) >= 0) {
              arrayItem[key] = this.service.ConvertDateToString(arrayItem[key]);
            }
          }
        }
      } else {
        if (this.dateList.indexOf(item) >= 0) {
          info[item] = this.service.ConvertDateToString(info[item]);
        }
      }
    }
  }

  onDateChange(e) {
    this.service.onManualDateEntry(e)
    this.onChange(null);
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  abstract onChange(event)

}