<form [formGroup]="coveragePlanForm">
    <!-- Start coveragePlan FormArray -->
    <div class="row">
        <div class="col form-array" formArrayName="coveragePlanArray">
            <div class="row form-array-item mt-4"
                *ngFor="let item of coveragePlanForm.get('coveragePlanArray')?.controls; let coveragePlanIndex = index;">
                <div class="col section" style="border-top: 1px solid #eee;">
                    <div class="row form-group">
                        <div class="col-12"><button type="button" class="remove-item"
                                (click)="RemoveCoveragePlan(coveragePlanIndex)"><i class="icon-delete"></i></button>
                        </div>
                    </div>
                    <div class="row" [formGroupName]="coveragePlanIndex">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-5 label">Name of Admitting Physician/Practice/Clinic/Group</div>
                                <div class="col-7 input-feild">
                                    <input type="text" class="form-control" (change)="onChange()"
                                        placeholder="Enter Name of Admitting Physician/Practice/Clinic/Group"
                                        formControlName="admittingName">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Hospital Where Privileged</div>
                                <div class="col-7 input-feild">
                                    <input type="text" class="form-control" (change)="onChange()"
                                        placeholder="Enter Hospital Where Privileged" formControlName="hospital">
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col">
                                    <eddy-upload-file [docInfo]="'Coverage Statement'" [userId]="userId"
                                        [text]="'Upload Coverage Statement'">
                                    </eddy-upload-file>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12"><button type="button" class="add-item rectangle-button orange-button"
                        (click)="AddCoveragePlan()">Add another coverage plan</button>
                </div>
            </div>
            <br />
        </div>
    </div>
    <!-- End coveragePlan FormArray -->
</form>