import { Component, OnInit, OnDestroy, Optional, Inject, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, ValidationErrors } from "@angular/forms";
import { IOption } from "src/app/view-models/ng-select-option";
import { SelectOptionService } from "src/app/services/select-option.service";
import { GoogleAddress } from "src/app/view-models/google-address";
import { HandleAddressChange } from "src/util";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonFormService } from "src/app/services/common-form.service";
import { TrainingData } from "src/app/view-models/training-data";
import { BaseCommonFormComponent } from "src/app/shared/utils/base.common.form.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

const ADDRESS_FIELDS = {
  street_number: "addressStreet",
  route: "addressApartment",
  locality: "addressCity",
  administrative_area_level_1: "addressState",
  postal_code: "addressZip",
  name: "name"
};

@Component({
  selector: "eddy-training",
  templateUrl: "training.component.html",
  styleUrls: ["training.component.scss"],
})
export class SaveTrainingComponent extends BaseCommonFormComponent<TrainingData> implements OnInit, OnDestroy {

  trainingForm: FormGroup;
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  departmentOptions: Array<IOption>;
  trainingInfo: TrainingData;
  dateList = ["startDatePrgAddInst", "endDatePrgAddInst"];
  optin = {
    dialCode: ""
  };
  subscriptions = [];

  @Output("onChangeTrainingData")
  onChangeForm = new EventEmitter();
  @Output() onSave = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    protected service: CommonFormService,
    public http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<SaveTrainingComponent>
  ) {
    super(service)
    if (dialogData) {
      const { info, providerId } = dialogData;
      this.trainingInfo = info;
      this.PatchValue();
      this.editModal = true;
      this.providerId = providerId;
    }
  }

  ngOnInit() {
    this.CreateForm();
    this.GetDepartmentOptions();
    this.PatchValue();
  }

  // Training Data FormArray Functions
  CreateForm(): FormGroup {
    this.trainingForm = this.builder.group({
      isInstFound: [true],
      codeSchool: ["", [Validators.required]],
      addressStreetInst1: [{ value: "", disabled: false }],
      addressStreetInst2: [{ value: "", disabled: false }],
      addressCityInst: [{ value: "", disabled: false }],
      addressStateInst: [{ value: "", disabled: false }],
      addressZipInst: [{ value: "", disabled: false }],
      phoneInst: [""],
      isProgramInst: [true, [Validators.required]],
      noteInst: [""],
      transitionalYearDetails: [""],
      typePrgAddInst: ["", [Validators.required]],
      startDatePrgAddInst: [null],
      endDatePrgAddInst: [null],
      deptPrgAddInst: [null],
      directorPrgAddInst: [""],
      emailDirPrgAddInst: ["", [Validators.email]],
      id: [null],
      trainingId: [null],
    });
    return this.trainingForm;
  }

  PatchValue() {

    this.trainingForm = this.builder.group({
      isInstFound: [this.trainingInfo ? this.trainingInfo.isInstFound : true],
      codeSchool: [this.trainingInfo ? this.trainingInfo.codeSchool : "", [Validators.required]],
      addressStreetInst1: [
        this.trainingInfo ? { value: this.trainingInfo.addressStreetInst1, disabled: false } : { value: "", disabled: false }
      ],
      addressStreetInst2: [
        this.trainingInfo ? { value: this.trainingInfo.addressStreetInst2, disabled: false } : { value: "", disabled: false }
      ],
      addressCityInst: [this.trainingInfo ? { value: this.trainingInfo.addressCityInst, disabled: false } : { value: "", disabled: false }],
      addressStateInst: [
        this.trainingInfo ? { value: this.trainingInfo.addressStateInst, disabled: false } : { value: "", disabled: false }
      ],
      addressZipInst: [this.trainingInfo ? { value: this.trainingInfo.addressZipInst, disabled: false } : { value: "", disabled: false }],
      phoneInst: [this.trainingInfo ? this.trainingInfo.phoneInst : ""],
      isProgramInst: [this.trainingInfo ? this.trainingInfo.isProgramInst : true, [Validators.required]],
      noteInst: [this.trainingInfo ? this.trainingInfo.noteInst : ""],
      transitionalYearDetails: [this.trainingInfo ? this.trainingInfo.transitionalYearDetails : ""],
      typePrgAddInst: [this.trainingInfo ? this.trainingInfo.typePrgAddInst : "", [Validators.required]],
      startDatePrgAddInst: [
        this.trainingInfo ? this.service.ConvertStringToDate(this.trainingInfo.startDatePrgAddInst) : null
      ],
      endDatePrgAddInst: [
        this.trainingInfo ? this.service.ConvertStringToDate(this.trainingInfo.endDatePrgAddInst) : null
      ],
      deptPrgAddInst: [this.trainingInfo ? this.trainingInfo.deptPrgAddInst : null],
      directorPrgAddInst: [this.trainingInfo ? this.trainingInfo.directorPrgAddInst : ""],
      emailDirPrgAddInst: [this.trainingInfo ? this.trainingInfo.emailDirPrgAddInst : "", [Validators.email]],
      id: [this.trainingInfo ? this.trainingInfo.id : null],
      trainingId: [this.trainingInfo ? this.trainingInfo.trainingId : null],
    });
    return this.trainingForm;
  }
  AddressChange({ address_components = [], name }) {
    let addressInfo: GoogleAddress = HandleAddressChange(
      address_components,
      ADDRESS_FIELDS
    );
    (this.trainingForm as FormGroup).patchValue({
      addressStreetInst1: addressInfo.addressStreet,
      addressStreetInst2: "",
      addressCityInst: addressInfo.addressCity,
      addressStateInst: addressInfo.addressState,
      addressZipInst: addressInfo.addressZip,
      codeSchool: name
    });
  }
  public requestAutocompleteItems = (text: string) => {
    const url = `${environment.API_URL}organization-info/all?search=${text}`;
    return this.http.get(url).pipe(map(data => data));
  };
  onItemAdded(data) {
    this.trainingForm.patchValue({
      addressStreetInst1: data.streetAddress,
      addressStreetInst2: "",
      // phoneInst: data.phone,
      addressCityInst: data.cityAddress,
      addressStateInst: data.stateAddress,
      addressZipInst: data.zipAddress
    });
  }
  GetDepartmentOptions() {
    const _subscribe = this.service.GetDepartments().subscribe(result => {
      this.departmentOptions = result.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
    });
    this.subscriptions.push(_subscribe);
  }

  onChange(event: any) {
    let trainingData = this.trainingForm.value;
    this.ConvertDate(trainingData);

    const isValid = this.trainingForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.trainingForm);
    this.onChangeForm.emit({ ...trainingData, isValid });
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveForm() {
    let trainingData = this.trainingForm.value;
    this.ConvertDate(trainingData);

    const isValid = this.trainingForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.trainingForm);

    this.onSave.emit(trainingData);
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let subscribe of this.subscriptions) {
        subscribe.unsubscribe();
      }
    }
  }
}
