import { Component, OnInit, OnDestroy, Optional, Inject, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { IOption } from "src/app/view-models/ng-select-option";
import { SelectOptionService } from "src/app/services/select-option.service";
import { HttpClient } from "@angular/common/http";
import { CommonFormService } from "src/app/services/common-form.service";
import { BaseCommonFormComponent } from "src/app/shared/utils/base.common.form.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { SpecialityData } from "src/app/view-models/speciality-data";
import { ALLIED_PROVIDER_TYPE, Areas_Of_Specialization, PROVIDER_TYPE_ABBR, Specialty_Certifications, Therapeutic_Modalities } from "src/app/constants";


@Component({
  selector: "eddy-save-specialties",
  templateUrl: "specialties.component.html",
  styleUrls: ["specialties.component.scss"],
})
export class SaveSpecialtyComponent extends BaseCommonFormComponent<SpecialityData> implements OnInit, OnDestroy {

  specialtyForm: FormGroup;
  specialtyInfo: SpecialityData;
  requiredList = ['isBoardCertPrimarySpec', 'isNotBoardCertPri',];
  dateList = ['dateBoardCertPrimary', 'primaryExpDate', 'primaryInitialCert', 'primaryReCert'];
  MD_DO_Options: Array<IOption>;
  DC_Options: Array<IOption>;
  DPM_Options: Array<IOption>;
  MD_DO_BOARD_OPTION: Array<IOption>;
  supportingOption = [
    'Additional training required', 'Not qualified due to age', 'Not Applicable to Provider', 'Did not pass the exam', 'Intention to test on date below'
  ];
  MD_DP_DC_Options: Array<IOption> = this.selectOptionService.getMD_DP_DC();
  DDS_DMD_Options: Array<IOption>;
  Allied_Health_Options: Array<IOption> = this.selectOptionService.getAllied_Health_BOARD();
  ALLIED_PROVIDERS_Options: Array<IOption>;
  areasOfSpecialization = Areas_Of_Specialization;
  therapeuticModalities = Therapeutic_Modalities;
  specialtyCertifications = Specialty_Certifications;
  taxonomyList;
  allSpecialities_Options: Array<IOption> = this.selectOptionService.getAllSpecialities();
  DDS_DMD_BOARD_OPTION: Array<IOption>
  DPM_BOARD_OPTION: Array<IOption>
  DC_BOARD_OPTION: Array<IOption>
  ALL_BOARDS_OPTION: Array<IOption>
  DPM_MD_BOARD_MERGE: Array<IOption>
  hideAllied: boolean = true;
  providerType: string;
  subscriptions = [];

  @Output("onChangeSpecialtyData")
  onChangeForm = new EventEmitter();
  @Output() onSave = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    protected service: CommonFormService,
    public http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    @Optional() private dialogRef: MatDialogRef<SaveSpecialtyComponent>
  ) {
    super(service)
    if (dialogData) {
      const { info, providerType } = dialogData;
      this.specialtyInfo = info;
      this.PatchValue();
      this.editModal = true;
      this.providerType = providerType;
    }
    this.MD_DO_Options = this.SortSpecialities(this.selectOptionService.getMD_DO());
    this.DC_Options = this.SortSpecialities(this.selectOptionService.getDC());
    this.DPM_Options = this.SortSpecialities(this.selectOptionService.getDPM());
    this.DDS_DMD_Options = this.SortSpecialities(this.selectOptionService.getDDS_DMD());
    this.ALLIED_PROVIDERS_Options = this.SortSpecialities(this.selectOptionService.getAlliedProviders());
    this.MD_DO_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getMD_DO_BOARD())
    this.DDS_DMD_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDDS_DMD_BOARD())
    this.DPM_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDPM_BOARD())
    this.DC_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDC_BOARD())
    this.ALL_BOARDS_OPTION = this.SortSpecialities(this.selectOptionService.getALL_BOARDS())
    this.DPM_MD_BOARD_MERGE = this.SortSpecialities([...this.MD_DO_BOARD_OPTION, ...this.DPM_BOARD_OPTION]);
  }

  ngOnInit() {
    this.CreateForm();
    this.getTaxonomyList();
    this.PatchValue();
  }

  SortSpecialities(arrToSort) {
    const targetArr: any = [];
    const tempArr: any = [];

    for (let i = 0; i < arrToSort.length; i++) {
      if (arrToSort[i].label === 'N/A') {
        targetArr.push(arrToSort[i])
      } else {
        tempArr.push(arrToSort[i])
      }
    }

    tempArr.sort((opt1: any, opt2: any) => {
      return (opt1.label.toLowerCase() < opt2.label.toLowerCase()) ?
        -1 :
        (opt1.label.toLowerCase() > opt2.label.toLowerCase()) ?
          1 : 0;
    })
    arrToSort = targetArr.concat(tempArr);
    return arrToSort;
  }

  getTaxonomyList() {
    const _subscribe = this.service.getTaxonomyList().subscribe((result) => {
      this.taxonomyList = result;
    })
    this.subscriptions.push(_subscribe);
  }

  // Specialty Data FormArray Functions
  CreateForm(): FormGroup {
    let formGroup = this.builder.group({
      id: [null],
      specialityId: [null],
      ADDADHD: [false],
      Addictions: [false],
      Adolescent: [false],
      Adolescents: [false],
      AdoptionFoster: [false],
      Adults: [false],
      AllAges: [false],
      AngerManagement: [false],
      AnxietyDisorders: [false],
      AutisticDisordersAsperger: [false],
      BariatricAssessments: [false],
      BehaviorManagementParentTraining: [false],
      BehaviorManagementParentTrainingP11: [false],
      BehaviorModificationTherapy: [false],
      BilingualTherapy: [false],
      BingeEatingDisorder: [false],
      BipolarDisorder: [false],
      BodyDsmorphicDisorder: [false],
      BriefSolutionFocused: [false],
      BriefTherapy: [false],
      Bullying: [false],
      CAP: [false],
      CBT: [false],
      ChildTherapy: [false],
      ChildTherapyN5: [false],
      ChildhoodEatingDisorders: [false],
      ChildrenA: [false],
      ChildrenB: [false],
      ChronicPainIssues: [false],
      CoOccurring: [false],
      CognitiveBehavioralTherapy: [false],
      CognitiveProcessingForTrauma: [false],
      ConductDisorders: [false],
      CopingWithMedicalIssues: [false],
      CouplesCounseling: [false],
      CrossCulturalAdaptation: [false],
      DBT: [false],
      DepressiveDisorders: [false],
      DevelopmentalDisabilities: [false],
      DialecticalBehavioralTherapy: [false],
      DissociativeIdentifyDisorders: [false],
      DivorceBlendedFamilyIssues: [false],
      DomesticViolenceIntimatePartnerViolence: [false],
      EAP: [false],
      EatingDisordersAnorexiaBulimia: [false],
      EducationalVocationalIssues: [false],
      ElectiveMutism: [false],
      EndOfLifeIssues: [false],
      EnuresisEncopresis: [false],
      ExcoriationDisorder: [false],
      ExpertWitnessTestimony: [false],
      FaithBasedCounseling: [false],
      FaithBasedCounselingFaith: [false],
      FamilyTherapy: [false],
      FitnessForDutyAssessments: [false],
      FitnessForDutyAssignments: [false],
      GeriatricAgingIssues: [false],
      Geriatrics: [false],
      GeropsychiatryAlzheimers: [false],
      Griefloss: [false],
      GroupTherapy: [false],
      Hoarding: [false],
      HumanTrafficking: [false],
      InfertilityIssues: [false],
      InsightOrientedPsychodynamicTherapy: [false],
      LGBTQA: [false],
      LifeCoaching: [false],
      MAT: [false],
      MHDevelopmentalDisabilityAdult: [false],
      MHDevelopmentalDisabilityChild: [false],
      Marital: [false],
      MedicalBehavioral: [false],
      MedicationManagement: [false],
      MenIssues: [false],
      MilitaryMilitaryFamily: [false],
      NeurocognitiveDisorders: [false],
      OCD: [false],
      ODD: [false],
      OtherAreasOfSpecialization: [false],
      OtherTherapeuticModalities: [false],
      PTSDAcute: [false],
      PTSDCchronic: [false],
      PanicDisorder: [false],
      PerpetratorsOfViolenceAbuse: [false],
      PersonalityDisorders: [false],
      PlayTherapy: [false],
      PostPartumDepression: [false],
      PreschoolReadinessInfantMentalHealth: [false],
      PrimarySubstanceAbuse: [false],
      PsychologicalTesting: [false],
      PsychoticDisorders: [false],
      RedCrossDisasterMH: [false],
      RefugeeIssues: [false],
      SchizophrenicDisorders: [false],
      SelfharmBehaviors: [false],
      SeverelyAndPersistentlyMentallyIll: [false],
      SexualDysfunctions: [false],
      SexualPhysicalAbuseAdult: [false],
      SexualPhysicalAbuseChild: [false],
      SleepDisordersInsomnia: [false],
      SocialSkillsTraining: [false],
      SolutionFocusedTherapy: [false],
      SomaticSymptomAndConversionDO: [false],
      SpecialtyCertificationsBariatricAssessments: [false],
      SpecialtyCertificationsBriefSolutionFocused: [false],
      SpecialtyCertificationsCAP: [false],
      SpecialtyCertificationsCBT: [false],
      SpecialtyCertificationsCognitiveProcessingForTrauma: [false],
      SpecialtyCertificationsDBT: [false],
      SpecialtyCertificationsEAP: [false],
      SpecialtyCertificationsLifeCoaching: [false],
      SpecialtyCertificationsOther: [false],
      SpecialtyCertificationsRedCrossDisasterMH: [false],
      SpecificLearningDisabilities: [false],
      SpecificPhobias: [false],
      SubstanceAlcoholChemicalAbuse: [false],
      TBI: [false],
      TFCBT: [false],
      TMS: [false],
      TTCBT: [false],
      TargetedCaseManagement: [false],
      TelementalHealth: [false],
      TicdisordersTourettes: [false],
      TransgenderIssues: [false],
      Trichotillomania: [false],
      WomensIssues: [false],
      WorkerCompDisability: [false],
      WorkersCompDisability: [false],
      ageGroupServed: [''],
      areasOfClinicalSpecialties: [false],
      certNumber: [''],
      code1BoardCertPri: [null],
      certifyingBoardAlliedHealth: [null],
      dateBoardCertPrimary: [null],
      dateIntentionToTest: [null],
      isBoardCertPrimarySpec: [false, [Validators.required]],
      isLifetimeCert: [false],
      // isNotBoardCertPri: ['Additional training required', [Validators.required]],
      isNotBoardCertPri: [
        'Additional training required',
        this.requiredIfValidator(() => !formGroup.get("isBoardCertPrimarySpec").value)
      ],
      mentalHealthType: [''],
      primaryCode1: [null],
      primaryCode2: [null],
      primaryCode3: [null],
      primaryExpDate: [null],
      primaryInitialCert: [null],
      primaryReCert: [null],
      primarySpecialty: [null],
      intentionDate: [null],
      haveTaxCode: [true],
      MOC_Participant: [false],
      taxonomyType: [null],
      taxonomyClassification: [null],
      taxonomySpecialization: [null],
    });
    const _subscribe = formGroup.get('isBoardCertPrimarySpec').valueChanges.subscribe((change) => {
      if (change) {
        // we do the same thing in specialityValue method too but that's not enough, because when that method(specialityValue) is running,
        // we can't access to newly created spacialities, so we have to do it here as well.
        formGroup.get('isNotBoardCertPri').clearValidators();
        formGroup.get('isNotBoardCertPri').patchValue(null);
        formGroup.get('intentionDate').clearValidators();
        formGroup.get('intentionDate').setErrors(null);
      } else {
        formGroup.get('isNotBoardCertPri').setValidators(Validators.required);
        // formGroup.get('code1BoardCertPri').setValidators(Validators.required);
        // formGroup.get('certifyingBoardAlliedHealth').setValidators(Validators.required);
        // formGroup.get('primaryInitialCert').setValidators(Validators.required);
      }
    });
    this.subscriptions.push(_subscribe);

    if (ALLIED_PROVIDER_TYPE[this.providerType]) formGroup.get('isBoardCertPrimarySpec').patchValue(true)
    formGroup.get('primarySpecialty').patchValue(PROVIDER_TYPE_ABBR[this.providerType])

    this.specialtyForm = formGroup;
    return this.specialtyForm;
  }

  PatchValue() {
    let formGroup = this.builder.group({});
    if (this.specialtyInfo) {
      if (!Object.keys(this.specialtyInfo).includes('primaryExpDate')) {
        formGroup.setControl('primaryExpDate', new FormControl(''))
      }
      for (let item of Object.keys(this.specialtyInfo)) {
        if (this.requiredList.indexOf(item) >= 0) {
          if (this.dateList.indexOf(item) >= 0) {
            formGroup.setControl(item, new FormControl(this.service.ConvertStringToDate(this.specialtyInfo[item]), Validators.required));
          } else {
            if (item === 'isNotBoardCertPri') {
              if (this.specialtyInfo.isBoardCertPrimarySpec === 'Yes' || this.specialtyInfo.isBoardCertPrimarySpec) {
                formGroup.setControl(item, new FormControl(this.specialtyInfo[item]));
              } else {
                formGroup.setControl(item, new FormControl(this.specialtyInfo[item], Validators.required));
                if (formGroup.controls.isNotBoardCertPri.value == 'Intention to test on date below') {
                  formGroup.controls.intentionDate.setValidators(Validators.required);
                }
              }
            } else {
              formGroup.setControl(item, new FormControl(this.specialtyInfo[item], Validators.required));
            }
          }
        } else {
          if (this.dateList.indexOf(item) >= 0) {
            formGroup.setControl(item, new FormControl(this.service.ConvertStringToDate(this.specialtyInfo[item])));
          }
          else {
            if (item === 'isLifetimeCert') {
              if (!this.specialtyInfo[item]) {

                formGroup.setControl(item, new FormControl(false));
              } else {
                formGroup.setControl(item, new FormControl(true));
              }
            } else {
              formGroup.setControl(item, new FormControl(this.specialtyInfo[item]));
            }
          }
        }
      }
      if (ALLIED_PROVIDER_TYPE[this.providerType]) formGroup.get('isBoardCertPrimarySpec').patchValue(true)
      formGroup.get('primarySpecialty').patchValue(PROVIDER_TYPE_ABBR[this.providerType])
      const _subscribe = formGroup.get('isBoardCertPrimarySpec').valueChanges.subscribe((change) => {
        if (change) {
          formGroup.get('isNotBoardCertPri').clearValidators();
          formGroup.get('isNotBoardCertPri').setErrors(null);
          // we patch null value to isNotBoardCertPri so if it was on 'intention '
          // we do the same thing in createSpacalityData method too, because when our method(specialityValue) is running,
          // we can't access to newly created spacialities, so we have to do it there as well.
          formGroup.get('isNotBoardCertPri').patchValue(null);
          formGroup.get('intentionDate').clearValidators();
          formGroup.get('intentionDate').setErrors(null);
        } else {
          formGroup.get('isNotBoardCertPri').setValidators(Validators.required);
        }
        formGroup.updateValueAndValidity();
      });
      this.subscriptions.push(_subscribe);
      if (!formGroup.get("dateBoardCertPrimary"))
        formGroup.setControl("dateBoardCertPrimary", new FormControl(null));

      if (!formGroup.get("primaryInitialCert"))
        formGroup.setControl("primaryInitialCert", new FormControl(null));

      if (!formGroup.get("primaryReCert"))
        formGroup.setControl("primaryReCert", new FormControl(null));

      this.specialtyForm = formGroup;
    } else {
      this.CreateForm();
    }

    return this.specialtyForm;
  }

  onChange(event: any) {
    let specialtyData = this.specialtyForm.value;
    this.ConvertDate(specialtyData);

    const isValid = this.specialtyForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.specialtyForm);
    this.onChangeForm.emit({ ...specialtyData, isValid });
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveForm() {
    let specialtyData = this.specialtyForm.value;
    this.ConvertDate(specialtyData);

    const isValid = this.specialtyForm.valid;
    if (!isValid) this.service.ValidateAllFormFields(this.specialtyForm);

    this.onSave.emit(specialtyData);
  }

  primarySpecialty(): string {
    let primarySpecialty = this.specialtyForm.controls.primarySpecialty.value;
    if (!primarySpecialty || primarySpecialty.indexOf('0') < 0) return primarySpecialty;
    return PROVIDER_TYPE_ABBR[primarySpecialty];
  }

  primaryCode3(): string {
    return this.specialtyForm.controls.primaryCode3.value
  }

  /**
   * Set Taxonomy Info to the form
   * @param taxonomyInfo Recieved Taxonomy Info
   */
  setTaxonomyInfo(taxonomyInfo) {
    this.specialtyForm.controls.taxonomyType.patchValue(taxonomyInfo ? taxonomyInfo.type : null);
    this.specialtyForm.controls.taxonomyClassification.patchValue(taxonomyInfo ? taxonomyInfo.classification : null);
    this.specialtyForm.controls.taxonomySpecialization.patchValue(taxonomyInfo ? taxonomyInfo.specialization : null);
  }

  getValueLable(obj) {
    let arr = [];
    for (let key in obj) {
      arr.push({ label: key, value: key });
    }
    arr = this.sortItems(arr);
    return arr;
  }

  sortItems(items) {
    return items.sort(function (a, b) {
      var x = a.label.toLowerCase();
      var y = b.label.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    });
  }

  taxonomyChanged(event, field) {
    if (field == 'haveTaxCode') {
      this.specialtyForm.controls.taxonomyType.patchValue(null);
      this.specialtyForm.controls.taxonomyClassification.patchValue(null);
      this.specialtyForm.controls.taxonomySpecialization.patchValue(null);
    }
    if (field == 'taxonomyType') {
      this.specialtyForm.controls.taxonomyClassification.patchValue(null);
      this.specialtyForm.controls.taxonomySpecialization.patchValue(null);
    }
    if (field == 'taxonomyClassification') {
      this.specialtyForm.controls.taxonomySpecialization.patchValue(null);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let subscribe of this.subscriptions) {
        subscribe.unsubscribe();
      }
    }
  }
}
