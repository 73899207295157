import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-visa",
  templateUrl: "save.visa.component.html",
  styleUrls: ["save.visa.component.scss"],
})
export class SaveProviderVisaComponent extends SaveDocumentItem<SaveProviderVisaComponent> {
  dateFields = ['expireVisa', 'issuanceVisa'];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderVisaComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
  ) {
    super(builder, service, dialogRef, _info);
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      expireVisa: [info ? this.service.ConvertStringToDate(info.expireVisa) : null, Validators.required],
      visaNum: [info ? info.visaNum : null],
      issuanceVisa: [info ? this.service.ConvertStringToDate(info.issuanceVisa) : null],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
