import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { GoogleAddress } from "src/app/view-models/google-address";
import { IOption } from "src/app/view-models/ng-select-option";
import { HandleAddressChange } from "src/util";
import { ADDRESS_FIELDS } from "../Insurance.component";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-insurance",
  templateUrl: "save.insurance.component.html",
  styleUrls: ["save.insurance.component.scss"],
})
export class SaveProviderInsuranceComponent extends SaveDocumentItem<SaveProviderInsuranceComponent> {
  dateFields = ['startingCarr', 'endingCarr', 'retroactiveDate', 'dateOcc', 'dateclaimfilled', 'dateSettl'];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  drugSchedulesOptions: Array<IOption> = this.selectOptionService.getDrugSchedules();
  options: any = {
    position: ['bottom', 'right'],
    timeOut: 400
  };
  _isPrimary: boolean = true;

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    dialogRef: MatDialogRef<SaveProviderInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) _info,
    private selectOptionService: SelectOptionService,
  ) {
    super(builder, service, dialogRef, _info);
    const { isPrimary } = _info;
    this._isPrimary = isPrimary !== null ? isPrimary: this._isPrimary;
  }

  CreateForm(info: any = null) {
    this.docForm = this.builder.group({
      selfInsured: [info ? info.selfInsured : ''],
      OtherCoverage: [info ? info.OtherCoverage : ''],
      isCoverage: [info ? info.isCoverage : false, Validators.required],
      policyCarr: [info ? info.policyCarr : '', Validators.required],
      carrName: [info ? info.carrName : ''],
      addressStreetCarr: [info ? info.addressStreetCarr : ''],
      addressStreet2Carr: [info ? info.addressStreet2Carr : ''],
      addressCityCarr: [info ? info.addressCityCarr : ''],
      addressStateCarr: [info ? info.addressStateCarr : ''],
      addressZipCarr: [info ? info.addressZipCarr : ''],
      phoneCarr: [info ? info.phoneCarr : ''],
      startingCarr: [info ? this.service.ConvertStringToDate(info.startingCarr) : '', Validators.required],
      endingCarr: [info ? this.service.ConvertStringToDate(info.endingCarr) : '', Validators.required],
      typeCovCarr: [info ? info.typeCovCarr : ''],
      unlimitedCovCarr: [info ? info.unlimitedCovCarr : false],
      amountCovOcc: [info ? info.amountCovOcc : ''],
      amountCovAgg: [info ? info.amountCovAgg : ''],
      isTailCov: [info ? info.isTailCov : false],
      retroactiveDate: [info ? this.service.ConvertStringToDate(info.retroactiveDate) : null],
      isMalpractice: [info ? info.isMalpractice : false, Validators.required],
      dateOcc: [info ? this.service.ConvertStringToDate(info.dateOcc) : null],
      dateclaimfilled: [info ? this.service.ConvertStringToDate(info.dateclaimfilled) : null],
      dateSettl: [info ? this.service.ConvertStringToDate(info.dateSettl) : null],
      claimStatus: [info ? info.claimStatus : ''],
      insCarr: [info ? info.insCarr : ''],
      allegDesc: [info ? info.allegDesc : ''],
      isPrimary: [info ? info.isPrimary : this._isPrimary],
      id: [info ? info.id : null],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }

  isPrimaryChange(event: any) {
    this.docForm.get("isPrimary").setValue(event);
  }

  // Google Address Search
  AddressChange({ address_components = [], name }) {
    let addressInfo: GoogleAddress = HandleAddressChange(address_components, ADDRESS_FIELDS);

    this.docForm.patchValue({
      carrName: name,
      addressStreetCarr: addressInfo.addressStreet,
      addressCityCarr: addressInfo.addressCity,
      addressStateCarr: addressInfo.addressState,
      addressZipCarr: addressInfo.addressZip,
    })
  }
}
