<div class="row form-group">
    <div class="col-5 label">If you know your Taxonomy Code, enter it here</div>
    <!-- <div class="col-7 input-feild">
        <ng-select bindLabel="label" bindValue="value" [items]="getValueLable(taxonomyList['individual'])"
            formControlName="taxonomyType" name="taxonomyType" (change)="taxonomyChanged($event,'taxonomyType', i)"
            placeholder="Select Type">
        </ng-select>
    </div> -->
    <div class="col d-flex justify-content-between align-items-center">
        <span class="searchInput">
            <input  [(ngModel)]="code" (blur)="search()" type="text" placeholder="Enter Taxonomy" />
            <img src="assets/images/organization-dashboard/search.png" class="searchIcon" (click)="search()" />
        </span>
    </div>
</div>