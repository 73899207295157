import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-vaccine",
  templateUrl: "save.vaccine.component.html",
  styleUrls: ["save.vaccine.component.scss"],
})
export class SaveProviderVaccineComponent extends SaveDocumentItem<SaveProviderVaccineComponent> {
  dateFields = ["issuanceVaccine"];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Inject(MAT_DIALOG_DATA) vaccineInfo,
    dialogRef: MatDialogRef<SaveProviderVaccineComponent>
  ) {
    super(builder, service, dialogRef, vaccineInfo);
  }

  /**
   * Create an empty form for a new vaccine
   * @returns FormGroup
   */
  CreateForm(info: any = null): FormGroup {
    this.docForm = this.builder.group({
      issuanceVaccine: [info ? info.issuanceVaccine : "", Validators.required],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
