<div class="row">
    <div class="col">
        <h4 class="mt-10 mb-20">Certification/Specialty</h4>
    </div>
</div>
<form [formGroup]="specialtyForm">
    <div class="row custom-form-design">
        <div class="col">
            <div class="row form-group">
                <div class="col">
                    <eddy-taxonomy-search-box (onSearch)="setTaxonomyInfo($event)">
                    </eddy-taxonomy-search-box>
                    <div class="row form-group">
                        <div class="col-5 label">If you need to search for your Taxonomy Code, select Type and continue
                        </div>
                        <div class="col-7 input-feild">
                            <ng-select bindLabel="label" bindValue="value"
                                [items]="taxonomyList && getValueLable(taxonomyList['individual'])"
                                formControlName="taxonomyType" name="taxonomyType"
                                (change)="taxonomyChanged($event,'taxonomyType')" placeholder="Select Type">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="taxonomyList && specialtyForm?.controls?.taxonomyType?.value">
                        <div class="col-5 label">Classification</div>
                        <div class="col-7 input-feild">
                            <ng-select bindLabel="label" bindValue="value"
                                [items]="getValueLable(taxonomyList['individual'][specialtyForm?.controls?.taxonomyType?.value])"
                                formControlName="taxonomyClassification" name="taxonomyClassification"
                                (change)="taxonomyChanged($event,'taxonomyClassification')"
                                placeholder="Select Classification">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group"
                        *ngIf="taxonomyList && specialtyForm?.controls?.taxonomyClassification?.value">
                        <div class="col-5 label">Specialization</div>
                        <div class="col-7 input-feild">
                            <ng-select bindLabel="label" bindValue="value"
                                [items]="getValueLable(taxonomyList['individual'][specialtyForm?.controls?.taxonomyType?.value][specialtyForm?.controls?.taxonomyClassification?.value])"
                                formControlName="taxonomySpecialization" name="taxonomySpecialization"
                                placeholder="Select Specialization">
                            </ng-select>
                        </div>
                    </div>
                    <table class="table taxonomy-table"
                        *ngIf="taxonomyList && specialtyForm?.controls?.taxonomySpecialization?.value">
                        <tr>
                            <th>Taxonomy Code:</th>
                            <td>{{ taxonomyList['individual']
                                [specialtyForm?.controls?.taxonomyType?.value]
                                [specialtyForm?.controls?.taxonomyClassification?.value]
                                [specialtyForm?.controls?.taxonomySpecialization?.value]['code']
                                || 'N/A' }}
                            </td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>
                                <span *ngIf="taxonomyList['individual']
                                    [specialtyForm?.controls?.taxonomyType?.value]
                                    [specialtyForm?.controls?.taxonomyClassification?.value]
                                    [specialtyForm?.controls?.taxonomySpecialization?.value]['status']=='A' "
                                    class="text-success">
                                    Active
                                </span>
                                <span *ngIf="taxonomyList['individual']
                                    [specialtyForm?.controls?.taxonomyType?.value]
                                    [specialtyForm?.controls?.taxonomyClassification?.value]
                                    [specialtyForm?.controls?.taxonomySpecialization?.value]['status']=='I' "
                                    class="text-danger">
                                    Inactive
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Definition:</th>
                            <td><small>
                                    {{ taxonomyList['individual']
                                    [specialtyForm?.controls?.taxonomyType?.value]
                                    [specialtyForm?.controls?.taxonomyClassification?.value]
                                    [specialtyForm?.controls?.taxonomySpecialization?.value]['definition']
                                    || 'N/A' }}
                                </small></td>
                        </tr>
                        <tr>
                            <th>Notes:</th>
                            <td><small>
                                    {{ taxonomyList['individual']
                                    [specialtyForm?.controls?.taxonomyType?.value]
                                    [specialtyForm?.controls?.taxonomyClassification?.value]
                                    [specialtyForm?.controls?.taxonomySpecialization?.value]['notes']
                                    || 'N/A' }}
                                </small></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row form-group"
                *ngIf="specialtyForm?.controls?.isNotBoardCertPri?.value == 'Intention to test on date below'">
                <div class="col-5 label">Select Date <span class="text-danger">*</span></div>
                <div class="col-7 input-feild date-picker">
                    <input class="form-control" placeholder="mm/dd/yyyy" name="intentionDate" placement="top"
                        ngbDatepicker type="date" (keyup)="onDateChange($event)" formControlName="intentionDate"
                        #intentionDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="calendar" (click)="intentionDate.toggle()" type="button">
                            <i class="icon-calender"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row form-group" [ngSwitch]="primarySpecialty()">
                <div class="col">
                    <div class="row form-group" *ngSwitchCase="'MD/DO'">
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="MD_DO_Options"
                                formControlName="primaryCode1" placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode1">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngSwitchCase="'DPM'">
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="DPM_Options"
                                formControlName="primaryCode2" placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode2">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngSwitchCase="'DC'">
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="DC_Options"
                                formControlName="primaryCode2" placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode2">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngSwitchCase="'DDS/DMD'">
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select class="auto-select" bindLabel="label" bindValue="value" [items]="DDS_DMD_Options"
                                formControlName="primaryCode2" placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode2">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngSwitchCase="'Other'">
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                [items]="ALLIED_PROVIDERS_Options" formControlName="primaryCode3"
                                placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode3">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngSwitchDefault>
                        <div class="col-5 label">Speciality</div>
                        <div class="col-7 input-feild">
                            <ng-select disabled="hideAllied" class="auto-select" bindLabel="label" bindValue="value"
                                [items]="allSpecialities_Options" formControlName="primaryCode3"
                                placeholder="Select Primary Specialty Code"
                                filterPlaceholder="Begin typing to Select Primary Specialty Code" name="primaryCode3">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="primaryCode3() === '515'">
                        <div class="col">
                            <div class="row form-group">
                                <div class="col-5 label">Age Group Served</div>
                                <div class="col-7 input-feild"><input type="text" class="form-control"
                                        placeholder="Enter Age Group Served" formControlName="ageGroupServed"></div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Mental Health Type</div>
                                <div class="col-7 input-feild"><input type="text" class="form-control"
                                        placeholder="Enter Mental Health Type" formControlName="mentalHealthType"></div>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-5 label">
                            Are you Board Certified?
                        </div>
                        <div class="col-7 input-feild">
                            No <mat-slide-toggle formControlName="isBoardCertPrimarySpec"
                                [checked]="specialtyForm?.controls?.isBoardCertPrimarySpec?.value === 'Yes'">Yes</mat-slide-toggle>
                        </div>
                    </div>

                    <div class="row" *ngIf="specialtyForm?.controls?.isBoardCertPrimarySpec?.value">
                        <div class="col">
                            <!-- -------------Certifying Board --------------- -->
                            <div class="row form-group" *ngSwitchCase="'MD/DO'">
                                <div class="col-5 label">Certifying Board</div>
                                <div class="col-7 input-feild">
                                    <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                        [items]="MD_DO_BOARD_OPTION" formControlName="code1BoardCertPri"
                                        placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="code1BoardCertPri">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group" *ngSwitchCase="'DPM'">
                                <div class="col-5 label">Certifying Board</div>
                                <div class="col-7 input-feild">
                                    <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                        [items]="DPM_MD_BOARD_MERGE" formControlName="code1BoardCertPri"
                                        placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="code1BoardCertPri">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group" *ngSwitchCase="'DC'">
                                <div class="col-5 label">Certifying Board</div>
                                <div class="col-7 input-feild">
                                    <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                        [items]="DC_BOARD_OPTION" formControlName="code1BoardCertPri"
                                        placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="code1BoardCertPri">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group" *ngSwitchCase="'DDS/DMD'">
                                <div class="col-5 label">Certifying Board</div>
                                <div class="col-7 input-feild">
                                    <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                        [items]="DDS_DMD_BOARD_OPTION" formControlName="code1BoardCertPri"
                                        placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="code1BoardCertPri">
                                    </ng-select>
                                </div>
                            </div>


                            <div class="row form-group" *ngSwitchCase="'Other'">
                                <div class="col-5 label">Certifying Board for Other</div>
                                <div class="col-7 input-feild">
                                    <ng-select class="auto-select" bindLabel="label" bindValue="value"
                                        [items]="Allied_Health_Options" formControlName="certifyingBoardAlliedHealth"
                                        placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="certifyingBoardAlliedHealth">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group" *ngSwitchDefault>
                                <div class="col-5 label">Certifying Board</div>
                                <div class="col-7 input-feild">
                                    <ng-select disabled="hideAllied" class="auto-select" bindLabel="label"
                                        bindValue="value" [items]="ALL_BOARDS_OPTION"
                                        formControlName="code1BoardCertPri" placeholder="Select Certifying Board Code"
                                        filterPlaceholder="Begin typing to Select Certifying Board Code"
                                        name="code1BoardCertPri">
                                    </ng-select>
                                </div>
                            </div>


                            <!-- -------------Certifying Board --------------- -->
                            <div class="row form-group">
                                <div class="col-5 label">Certification Number</div>
                                <div class="col-7 input-feild"><input type="text" class="form-control"
                                        placeholder="Enter Certification Number" formControlName="certNumber"></div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Date of Initial Certification</div>
                                <div class="col-7 input-feild date-picker">
                                    <input class="form-control" placeholder="mm/dd/yyyy" name="primaryInitialCert"
                                        placement="top" ngbDatepicker type="date" (keyup)="onDateChange($event)"
                                        formControlName="primaryInitialCert" #primaryInitialCert="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="calendar" (click)="primaryInitialCert.toggle()" type="button">
                                            <i class="icon-calender"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-5 label">Date of Recertification</div>
                                <div class="col-7 input-feild date-picker">
                                    <input class="form-control" placeholder="mm/dd/yyyy" name="primaryReCert"
                                        placement="top" ngbDatepicker type="date" (keyup)="onDateChange($event)"
                                        formControlName="primaryReCert" #primaryReCert="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="calendar" (click)="primaryReCert.toggle()" type="button">
                                            <i class="icon-calender"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="row form-group">
                                <div class="col-5 label">Select for Lifetime Certification</div>
                                <div class="col-7 input-feild">No <mat-slide-toggle
                                        formControlName="isLifetimeCert">Yes</mat-slide-toggle>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-5 label">
                                    MOC Participant
                                </div>
                                <div class="col-7 input-feild">
                                    No <mat-slide-toggle formControlName="MOC_Participant">Yes
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="row form-group"
                                *ngIf="!specialtyForm?.controls?.isLifetimeCert?.value && !specialtyForm?.controls?.MOC_Participant?.value">
                                <div class="col-5 label">Date of Expiration</div>
                                <div class="col-7 input-feild date-picker">
                                    <input class="form-control" placeholder="mm/dd/yyyy" name="primaryExpDate"
                                        placement="top" ngbDatepicker type="date" (keyup)="onDateChange($event)"
                                        formControlName="primaryExpDate" #primaryExpDate="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="calendar" (click)="primaryExpDate.toggle()" type="button">
                                            <i class="icon-calender"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-5 label">
                            Do you wish to detail areas of clinical specialties?
                            <small class="form-text text-muted">(ages treated, areas of
                                specialization,
                                therapeutic modalities, specialty certifications)</small>
                        </div>
                        <div class="col-7 input-feild">No <mat-slide-toggle
                                formControlName="areasOfClinicalSpecialties">Yes</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="specialtyForm?.controls?.areasOfClinicalSpecialties?.value">
                        <div class="col">
                            <nb-accordion multi class="accordion-list">
                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Ages Treated
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="AllAges">All Ages
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="ChildrenA">Children 0-5
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="ChildrenB">Children
                                                            6-12
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="Adolescents">
                                                            Adolescents 13-17
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="Adults">Adults 18+
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-12 input-feild">
                                                        <mat-checkbox class="example-margin"
                                                            formControlName="Geriatrics">Geriatrics
                                                            70+
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>

                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Areas of Specialization
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-6">
                                                <span *ngFor="let item of areasOfSpecialization.partA">
                                                    <div class="row form-group">
                                                        <div class="col-12 input-feild">
                                                            <mat-checkbox class="example-margin"
                                                                formControlName="{{item.field}}">
                                                                {{ item.title }}</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class="col-6">
                                                <span *ngFor="let item of areasOfSpecialization.partB">
                                                    <div class="row form-group">
                                                        <div class="col-12 input-feild">
                                                            <mat-checkbox class="example-margin"
                                                                formControlName="{{item.field}}">
                                                                {{ item.title }}</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>

                                <nb-accordion-item>
                                    <nb-accordion-item-header>
                                        Therapeutic Modalities
                                    </nb-accordion-item-header>
                                    <nb-accordion-item-body>
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngFor="let item of therapeuticModalities">
                                                    <div class="row form-group">
                                                        <div class="col-12 input-feild">
                                                            <mat-checkbox class="example-margin"
                                                                formControlName="{{item.field}}">
                                                                {{ item.title }}</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>
                            </nb-accordion>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row button-section">
                <div class="col-5 form-group">
                    <button type="button" (click)="closeModal()" class="go-back rectangle-button mb-0">
                        Cancel</button>
                </div>
                <div class="col-7 form-group">
                    <button (click)="saveForm()" class="rectangle-button green-button-two mb-0"><i
                            class="icon-check"></i> Save</button>
                </div>
            </div>
        </div>
    </div>
</form>