import { Component, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonFormService } from "src/app/services/common-form.service";
import { SaveDocumentItem } from "src/app/shared/utils/saveDocumentItem";

@Component({
  selector: "certification-save-health-assessment",
  templateUrl: "save.health-assessment.component.html",
  styleUrls: ["save.health-assessment.component.scss"],
})
export class SaveProviderHealthAssessmentComponent extends SaveDocumentItem<SaveProviderHealthAssessmentComponent> {
  dateFields = ["issuanceHealthAssessment"];

  constructor(
    builder: FormBuilder,
    service: CommonFormService,
    @Inject(MAT_DIALOG_DATA) _info,
    dialogRef: MatDialogRef<SaveProviderHealthAssessmentComponent>
  ) {
    super(builder, service, dialogRef, _info);
  }

  /**
   * Create an empty form for a new document Item
   * @returns FormGroup
   */
  CreateForm(info: any = null): FormGroup {
    this.docForm = this.builder.group({
      issuanceHealthAssessment: [info ? info.issuanceHealthAssessment : "", Validators.required],
      attachments: [info ? info.attachments : []],
    });

    return this.docForm;
  }
}
